export enum Param {
  PAGE = '_p',
  LIMIT = '_l',
  SORT_COLUMN = '_sc',
  SORT_ASCENDING = '_sa',
  SEARCH_KEYWORD = '_k',
  FROM = 'from',
  TO = 'to',
  BOOKMARK = 'bookmark',
  HAS_RUNNING_TIME = 'hasRunningTime',
  VIEW_TYPE = 'viewType',
  SHOW_PRODUCT_COUNT = 'showProductCount',
}
