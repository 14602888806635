import defaultFetch from '@common/libs/fetch';
import RegionManager from '@common/regions';
import {
  TAiClipMp4Clip,
  TAiClipMp4ClipDownloadable,
  TAiClipMp4ClipProgress,
  TAiClipSectionBurnedMp4,
  TAiClipTemplatePreset,
  TAiClip,
  TAiClipArchive,
  TAiClipArchiveDownloadable,
  TAiClipArchiveProgress,
  TAiClipSection,
  TAiClipSectionSrt,
  TUpdateAiClipSectionWordsStyle,
  TUpdateAiClipSectionBookmark,
  TUpdateAiClipSectionSubtitle,
  TUpdateAiClipSectionTags,
  TUpdateAiClipSectionTitle,
  TAiClipCategory,
  TAiClipLanguage,
  TAiClipProgress,
  TAiClipSttVersion,
  TUpdateAiClipDescription,
  TUpdateAiClipTitle,
  TAiClipBurnedMp4,
  TUpdateAiClipSectionSubtitleWord,
  TDeleteAiClipSectionSubtitleWord,
  TUpdateAiClipSection,
  TUpdateAiClipSectionTime,
  TUpdateAiClipSectionSubtitles,
  TUpdateAiClipSectionMaskingOption,
  TCreateAiClipBulk,
  TUpdateAiClipSectionTitleOption,
  TUpdateAiClipSectionSubtitleOption,
  TAiClipConfig,
  TAiClipFontLanguage,
  TAiClipFont,
} from '@localTypes/ai-clip';

import { useRouter } from '@hooks/useRouter';
import { Paginated } from '@localTypes/pagination';
import qs from 'querystring';
import useSWR from 'swr';
import { Param } from '@common/param';
import { isString } from 'lodash';
import { useSWRGetAiClipThisMonthCreditUsage } from '@hooks/ai-clip/useSWRGetAiClipThisMonthCreditUsage';

export const createAiClipBulkApi = (bulkItems: TCreateAiClipBulk[]): Promise<TAiClip> =>
  defaultFetch(`${RegionManager.apiEndpoint}/ai/clip/bulk`, 'POST', { requests: bulkItems });

const getAiClipsApi = (isMonitor: boolean, params?: any): Promise<Paginated<TAiClip[]>> => {
  const encodedParams = params ? `?${qs.encode(params)}` : '';
  const url = isMonitor
    ? `${RegionManager.apiEndpoint}/ai/clip/monitor${encodedParams}`
    : `${RegionManager.apiEndpoint}/ai/clip${encodedParams}`;

  return defaultFetch(url, 'GET');
};

export function useSWRGetAiClips({ isDisabled, isMonitor = false }: { isDisabled?: boolean; isMonitor?: boolean }) {
  const { refreshThisMonthCreditUsage } = useSWRGetAiClipThisMonthCreditUsage();
  const router = useRouter();

  return useSWR<Paginated<TAiClip[]>>(
    isDisabled
      ? null
      : isMonitor
      ? [
          'getAiClipsApi',
          router.query[Param.PAGE],
          router.query[Param.LIMIT],
          router.query[Param.SEARCH_KEYWORD],
          router.query['status'],
        ]
      : ['getAiClipsApi', router.query[Param.PAGE], router.query[Param.LIMIT], router.query[Param.SEARCH_KEYWORD]],
    () => getAiClipsApi(isMonitor, router.query),
    {
      onSuccess: () => {
        refreshThisMonthCreditUsage();
      },
    },
  );
}

export const getAiClipByIdApi = (aiClipId: string): Promise<TAiClip> =>
  defaultFetch(`${RegionManager.apiEndpoint}/ai/clip/${aiClipId}`, 'GET');

/**
 * `invalidateBurnedMp4`
 * true -> 합성된 mp4 삭제
 * false (default) -> 합성된 mp4 삭제하지 않음
 */
export const deleteAiClipApi = (aiClipId: string) =>
  defaultFetch(`${RegionManager.apiEndpoint}/ai/clip/${aiClipId}?invalidateBurnedMp4=true`, 'DELETE');

export const getAiClipProgressApi = (aiClipId: string): Promise<TAiClipProgress> =>
  defaultFetch(`${RegionManager.apiEndpoint}/ai/clip/${aiClipId}/progress`, 'GET');

export const updateAiClipTitleApi = ({ aiClipId, title }: TUpdateAiClipTitle) =>
  defaultFetch(`${RegionManager.apiEndpoint}/ai/clip/${aiClipId}/title`, 'PUT', { title });

export const updateAiClipDescriptionApi = ({ aiClipId, description }: TUpdateAiClipDescription) =>
  defaultFetch(`${RegionManager.apiEndpoint}/ai/clip/${aiClipId}/description`, 'PUT', { description });

// AI Clip Language
export const getAiClipLanguagesApi = (): Promise<TAiClipLanguage[]> =>
  defaultFetch(`${RegionManager.apiEndpoint}/ai/clip/language`, 'GET');

// AI Clip Category (Prompt)
export const getAiClipCategoriesApi = (): Promise<TAiClipCategory[]> =>
  defaultFetch(`${RegionManager.apiEndpoint}/ai/clip/category`, 'GET');

// AI Clip STT Version
export const getAiClipSttVersionsApi = (): Promise<TAiClipSttVersion[]> =>
  defaultFetch(`${RegionManager.apiEndpoint}/ai/clip/stt/version`, 'GET');

/**
 * @param sectionIds 빈배열 O → 전체 다운로드, 빈배열 X → 선택 다운로드
 */
export const checkAiClipArchiveDownloadableApi = (
  aiClipId: string,
  sectionIds: string[],
): Promise<TAiClipArchiveDownloadable> =>
  defaultFetch(`${RegionManager.apiEndpoint}/ai/clip/${aiClipId}/archive/downloadable`, 'POST', sectionIds);

export const createAiClipArchiveApi = (aiClipId: string, sectionIds: string[]): Promise<TAiClipArchive> =>
  defaultFetch(`${RegionManager.apiEndpoint}/ai/clip/${aiClipId}/archive`, 'POST', sectionIds);

export const getAiClipArchiveProgressApi = (
  aiClipId: string,
  aiClipArchiveId: string,
): Promise<TAiClipArchiveProgress> =>
  defaultFetch(`${RegionManager.apiEndpoint}/ai/clip/${aiClipId}/archive/${aiClipArchiveId}/progress`, 'GET');

export const downloadAiClipArchiveApi = (aiClipId: string, aiClipArchiveId: string): Promise<TAiClipArchive> =>
  defaultFetch(`${RegionManager.apiEndpoint}/ai/clip/${aiClipId}/archive/${aiClipArchiveId}/download`, 'GET');

export const cancelAiClipArchiveApi = (aiClipId: string, aiClipArchiveId: string) =>
  defaultFetch(`${RegionManager.apiEndpoint}/ai/clip/${aiClipId}/archive/${aiClipArchiveId}/cancel`, 'DELETE');

export const regenerateAiClipApi = (aiClipId: string): Promise<TAiClip> =>
  defaultFetch(`${RegionManager.apiEndpoint}/ai/clip/${aiClipId}/regenerate`, 'PUT');

// AI Clip Section
export const getAiClipSectionApi = (aiClipId: string, sectionId: string): Promise<TAiClipSection> =>
  defaultFetch(`${RegionManager.apiEndpoint}/ai/clip/${aiClipId}/section/${sectionId}`, 'GET');

/**
 * `invalidateBurnedMp4`
 * true -> 합성된 mp4 삭제
 * false (default) -> 합성된 mp4 삭제하지 않음
 */
export const deleteAiClipSectionApi = (aiClipId: string, sectionId: string) =>
  defaultFetch(
    `${RegionManager.apiEndpoint}/ai/clip/${aiClipId}/section/${sectionId}?invalidateBurnedMp4=true`,
    'DELETE',
  );

export const updateAiClipSectionBookmarkApi = ({
  aiClipId,
  sectionId,
  bookmark,
}: TUpdateAiClipSectionBookmark): Promise<TAiClipSection> =>
  defaultFetch(`${RegionManager.apiEndpoint}/ai/clip/${aiClipId}/section/${sectionId}/bookmark/${bookmark}`, 'PUT');

export const updateAiClipSectionTitleApi = ({
  aiClipId,
  sectionId,
  title,
}: TUpdateAiClipSectionTitle): Promise<TAiClipSection> =>
  defaultFetch(`${RegionManager.apiEndpoint}/ai/clip/${aiClipId}/section/${sectionId}/title`, 'PUT', { title });

export const updateAiClipSectionTagsApi = ({
  aiClipId,
  sectionId,
  tags,
}: TUpdateAiClipSectionTags): Promise<TAiClipSection> =>
  defaultFetch(`${RegionManager.apiEndpoint}/ai/clip/${aiClipId}/section/${sectionId}/tag`, 'PUT', { tags });

export const updateAiClipSectionMaskingOptionApi = ({
  aiClipId,
  sectionId,
  maskingOption,
}: TUpdateAiClipSectionMaskingOption): Promise<TAiClipSection> =>
  defaultFetch(`${RegionManager.apiEndpoint}/ai/clip/${aiClipId}/section/${sectionId}/masking`, 'PUT', {
    maskingOption,
  });

export const updateAiClipSectionSubtitleApi = ({
  aiClipId,
  sectionId,
  subtitleId,
  text,
}: TUpdateAiClipSectionSubtitle): Promise<TAiClipSection> =>
  defaultFetch(`${RegionManager.apiEndpoint}/ai/clip/${aiClipId}/section/${sectionId}/subtitle/${subtitleId}`, 'PUT', {
    text,
  });

//update clip time
export const updateAiClipSectionTimeApi = ({
  startTime,
  endTime,
  aiClipId,
  sectionId,
}: TUpdateAiClipSectionTime): Promise<TAiClipSection> =>
  defaultFetch(`${RegionManager.apiEndpoint}/ai/clip/${aiClipId}/section/${sectionId}/time`, 'PUT', {
    startTime,
    endTime,
  });

// update clip subtitles
export const updateAiClipSectionSubtitlesApi = ({
  aiClipId,
  sectionId,
  subtitles,
}: TUpdateAiClipSectionSubtitles): Promise<TAiClipSection> =>
  defaultFetch(`${RegionManager.apiEndpoint}/ai/clip/${aiClipId}/section/${sectionId}/subtitle`, 'PUT', {
    subtitles,
  });

/**
 * Use when undo/redo only.
 */
export const updateAiClipSectionApi = ({
  aiClipId,
  sectionId,
  startTime,
  endTime,
  subtitles,
  deletedWords,
}: TUpdateAiClipSection): Promise<TAiClipSection> =>
  defaultFetch(`${RegionManager.apiEndpoint}/ai/clip/${aiClipId}/section/${sectionId}`, 'PUT', {
    startTime,
    endTime,
    subtitles,
    deletedWords,
  });

export const updateAiClipSectionSubtitleWordApi = ({
  aiClipId,
  sectionId,
  subtitleId,
  wordId,
  text,
  visible,
}: TUpdateAiClipSectionSubtitleWord): Promise<TAiClipSection> =>
  defaultFetch(
    `${RegionManager.apiEndpoint}/ai/clip/${aiClipId}/section/${sectionId}/subtitle/${subtitleId}/word/${wordId}`,
    'PUT',
    {
      text,
      visible,
    },
  );

export const deleteAiClipSectionSubtitleWordApi = ({
  aiClipId,
  sectionId,
  subtitleId,
  wordId,
}: TDeleteAiClipSectionSubtitleWord) =>
  defaultFetch(
    `${RegionManager.apiEndpoint}/ai/clip/${aiClipId}/section/${sectionId}/subtitle/${subtitleId}/word/${wordId}`,
    'DELETE',
  );

// AI Clip Section SRT
export const getAiClipSectionSrtApi = (aiClipId: string, sectionId: string): Promise<TAiClipSectionSrt> =>
  defaultFetch(`${RegionManager.apiEndpoint}/ai/clip/${aiClipId}/section/${sectionId}/download/srt`, 'GET');

export const checkAiClipMp4ClipDownloadableApi = (
  category: 'AI_CLIP' | 'AI_CLIP_BURNED',
  aiClipId: string,
  sectionId: string,
  templatePresetId?: string,
): Promise<TAiClipMp4ClipDownloadable> => {
  const url = new URL(`${RegionManager.apiEndpoint}/ai/clip/${aiClipId}/section/${sectionId}/downloadable/mp4`);

  if (isString(templatePresetId)) {
    url.searchParams.set('mediaConvertOptionPresetId', templatePresetId);
  }

  url.searchParams.set('mp4ClipCategory', category);

  return defaultFetch(url.toString(), 'GET');
};

export const downloadAiClipMp4ClipApi = ({
  category,
  aiClipId,
  sectionId,
  templatePresetId,
  mp4ClipId,
}: {
  category: 'AI_CLIP' | 'AI_CLIP_BURNED';
  aiClipId: string;
  sectionId: string;
  templatePresetId?: string;
  mp4ClipId?: string;
}): Promise<TAiClipMp4Clip> => {
  const url = new URL(`${RegionManager.apiEndpoint}/ai/clip/${aiClipId}/section/${sectionId}/download/mp4`);

  if (isString(templatePresetId)) {
    url.searchParams.set('mediaConvertOptionPresetId', templatePresetId);
  }
  if (isString(mp4ClipId)) {
    url.searchParams.set('mp4ClipId', mp4ClipId);
  }

  url.searchParams.set('mp4ClipCategory', category);

  return defaultFetch(url.toString(), 'GET');
};

export const getAiClipMp4ClipProgressApi = (mp4ClipId: string): Promise<TAiClipMp4ClipProgress> =>
  defaultFetch(`${RegionManager.apiEndpoint}/mp4Clip/${mp4ClipId}/progress`, 'GET');

export const cancelAiClipMp4ClipApi = (mp4ClipId: string) =>
  defaultFetch(`${RegionManager.apiEndpoint}/mp4Clip/${mp4ClipId}/cancel`, 'DELETE');

export const getAiClipTemplatePresetsApi = (aiClipId: string): Promise<{ presets: TAiClipTemplatePreset[] }> =>
  defaultFetch(`${RegionManager.apiEndpoint}/ai/clip/${aiClipId}/template/preset`, 'GET');

export const getAiClipSectionBurnedMp4sApi = (
  aiClipId: string,
  sectionId: string,
  templatePresetIds: string[],
): Promise<TAiClipSectionBurnedMp4[]> => {
  const queryParams = `?mediaConvertOptionPresetIds=${templatePresetIds.join(',')}`;

  return defaultFetch(
    `${RegionManager.apiEndpoint}/ai/clip/${aiClipId}/section/${sectionId}/burnedMp4${queryParams}`,
    'GET',
  );
};

const getAiClipBurnedMp4sApi = (params?: any): Promise<Paginated<TAiClipBurnedMp4[]>> => {
  const encodedParams = params ? `?${qs.encode(params)}` : '';

  return defaultFetch(`${RegionManager.apiEndpoint}/ai/clip/burnedMp4${encodedParams}`, 'GET');
};

export function useSWRGetAiClipBurnedMp4s(isDisabled?: boolean) {
  const router = useRouter();

  return useSWR<Paginated<TAiClipBurnedMp4[]>>(
    isDisabled
      ? null
      : [
          'getAiClipBurnedMp4sApi',
          router.query[Param.PAGE],
          router.query[Param.LIMIT],
          router.query[Param.SEARCH_KEYWORD],
        ],
    () => getAiClipBurnedMp4sApi(router.query),
  );
}

export const deleteAiClipBurnedMp4 = (burnedMp4ClipId: string) =>
  defaultFetch(`${RegionManager.apiEndpoint}/mp4Clip/${burnedMp4ClipId}/invalidate`, 'DELETE');

// AI Clip Source Campaign Keyframes
export const getAiClipCampaignVideo = (campaignId: string) =>
  defaultFetch(`${RegionManager.apiEndpoint}/ai/clip/source/campaign/${campaignId}`, 'GET');

/**
 * Font File
 */
export const getAiClipFontsApi = ({
  containDefaultFonts = true,
  languages,
}: {
  containDefaultFonts?: boolean;
  languages: TAiClipFontLanguage[];
}): Promise<TAiClipFont[]> => {
  const url = new URL(`${RegionManager.apiEndpoint}/font`);

  url.searchParams.set('containDefaultFonts', `${containDefaultFonts}`);
  url.searchParams.set('languages', languages.join(','));

  return defaultFetch(url.toString(), 'GET');
};

/**
 * Font Styling
 */
// Section title style
export const updateAiClipSectionTitleOptionApi = ({
  aiClipId,
  sectionId,
  titleOption,
}: TUpdateAiClipSectionTitleOption): Promise<TAiClipSection> =>
  defaultFetch(`${RegionManager.apiEndpoint}/ai/clip/${aiClipId}/section/${sectionId}/title/style`, 'PUT', {
    titleOption,
  });

// Section Subtitle(default) style
export const updateAiClipSectionSubtitleOptionApi = ({
  aiClipId,
  sectionId,
  defaultSubtitleOption,
}: TUpdateAiClipSectionSubtitleOption): Promise<TAiClipSection> =>
  defaultFetch(`${RegionManager.apiEndpoint}/ai/clip/${aiClipId}/section/${sectionId}/subtitle/style`, 'PUT', {
    defaultSubtitleOption,
  });

// Section subtitle(segment) Style
export const updateAiClipSectionWordsStyleApi = ({
  aiClipId,
  sectionId,
  subtitleId,
  wordIds,
  style,
}: TUpdateAiClipSectionWordsStyle): Promise<TAiClipSection> =>
  defaultFetch(
    `${RegionManager.apiEndpoint}/ai/clip/${aiClipId}/section/${sectionId}/subtitle/${subtitleId}/word/style`,
    'PUT',
    {
      wordIds,
      style,
    },
  );

export const getAiClipAssApi = (aiClipId: string, sectionId: string): Promise<string> =>
  defaultFetch(`${RegionManager.apiEndpoint}/ai/clip/${aiClipId}/section/${sectionId}/ass`, 'GET');

/**
 * AI Clip Config
 */
export const getAiClipConfigApi = (): Promise<TAiClipConfig> =>
  defaultFetch(`${RegionManager.apiEndpoint}/ai/clip/config`, 'GET');

/**
 * @reClipping
 */
export const getAiClipHasReClipping = (aiClipId: string): Promise<boolean> =>
  defaultFetch(`${RegionManager.apiEndpoint}/ai/clip/${aiClipId}/hasReClipping`, 'GET');

export const createAiClipReClipping = (aiClipId: string) =>
  defaultFetch(`${RegionManager.apiEndpoint}/ai/clip/${aiClipId}/reClipping`, 'POST');
