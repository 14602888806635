export const SERVER_REGION = {
  USE1: 'USE1',
  APNE2: 'APNE2',
};

export const PHASE = {
  LOCAL: 'LOCAL',
  QA: 'QA',
  DEV: 'DEV',
  PROD: 'PROD',
  STG: 'STG',
};
const getPhase = () => {
  switch (location.hostname) {
    case 'localhost':
      return PHASE.LOCAL;
    case 'dev-admin.shoplive.cloud':
    case 'dyfhr1jeah5pn.cloudfront.net':
    case 'dev-adm.shoplive.cloud':
    case 'dev-ebay-adm.shoplive.cloud':
    case 'dev-yeosim.shoplive.cloud':
    case 'dev-adm-lab1.shoplive.cloud':
    case 'dev-adm-lab2.shoplive.cloud':
    case 'dev-adm-lab3.shoplive.cloud':
    case 'dev-adm-lab4.shoplive.cloud':
    case 'd35enwazos1561.cloudfront.net':
    case 'dev-adm-11st.shoplive.cloud':
      return PHASE.DEV;

    case 'd3lx5u5vkku6zh.cloudfront.net':
    case 'qa-admin.shoplive.cloud':
    case 'qa-adm.shoplive.cloud':
      return PHASE.QA;

    case 'stg-adm.shoplive.cloud':
      return PHASE.STG;
  }
  return PHASE.PROD;
};

export const phase = getPhase();

export const getLiveJsHost = () => {
  switch (phase) {
    case PHASE.LOCAL:
      return 'http://localhost:4000';
    case PHASE.DEV:
      return 'https://dev-static.shoplive.cloud';
    case PHASE.STG:
    case PHASE.QA:
      return 'https://stg-static.shoplive.cloud';
  }
  return 'https://static.shoplive.cloud';
};

export const getIsEbayHost = () => {
  switch (location.host) {
    case 'localhost:3030':
    case 'dev-ebay.shoplive.cloud':
    case 'dev-ebay-adm.shoplive.cloud':
    case 'ebay.shoplive.cloud':
    case 'ebaylive.shoplive.cloud':
      return true;
  }
  return false;
};

export const getIsYeosimHost = () => {
  switch (location.host) {
    case 'localhost:3031':
    case 'yeosim.shoplive.cloud':
    case 'dev-yeosim.shoplive.cloud':
      return true;
  }
  return false;
};

export const getApiEndpointByDomain = () => {
  switch (phase) {
    case PHASE.LOCAL:
    case PHASE.DEV:
      return 'https://dev-admin.shoplive.cloud/api';
    case PHASE.STG:
      return 'https://stg-admin.shoplive.cloud/api';
    case PHASE.QA:
      return 'https://qa-admin.shoplive.cloud/api';
  }

  // 미국 도메인이면 미국 서버를 우선적으로 호출해야한다.
  switch (location.hostname) {
    case 'adm.us1.shoplive.cloud':
    case 'admin.us1.shoplive.cloud':
    case 'ebay.shoplive.cloud':
    case 'ebaylive.shoplive.cloud':
      return 'https://admin.us1.shoplive.cloud/api';
  }

  return 'https://admin.shoplive.cloud/api';
};

export const getPluginJSUrl = () => {
  switch (phase) {
    case PHASE.LOCAL:
      return 'http://localhost:8080/shoplive.js';
    case PHASE.DEV:
      return 'https://dev-static.shoplive.cloud/shoplive.js';
    case PHASE.QA:
      return 'https://stg-static.shoplive.cloud/shoplive.js';
  }
  return 'https://static.shoplive.cloud/shoplive.js';
};

export const getShortformJSUrl = () => {
  switch (phase) {
    case PHASE.LOCAL:
      return 'http://localhost:8090/shoplive-shortform.js';
    case PHASE.DEV:
      return 'https://dev-static.shoplive.cloud/shoplive-shortform.js';
    case PHASE.QA:
      return 'https://stg-static.shoplive.cloud/shoplive-shortform.js';
  }
  return 'https://static.shoplive.cloud/shoplive-shortform.js';
};

export const getShortformJsPhase = () => {
  switch (phase) {
    case PHASE.LOCAL:
      return 'LOCAL';
    case PHASE.DEV:
      return 'DEV';
    case PHASE.QA:
      return 'STAGE';
  }
  return null;
};

export const getShortformShowCollectionHtml = () => {
  return 'https://shortform.shoplive.show/collection.html';
};

export const getServerRegion = (currentApiEndpoint: string) => {
  if (currentApiEndpoint.includes('.us1.')) {
    return SERVER_REGION.USE1;
  } else {
    return SERVER_REGION.APNE2;
  }
};
