import { t } from '@common/helper/languages';
import { useAuthorityState } from '@context/authority/AuthorityContext';

/**
 * `t` 사용시, language 상태를 가지는 useAuthorityState 훅을 포함시키지 않으면 언어가 변환되지 않음.
 */
export function useTranslation() {
  const { language } = useAuthorityState();

  return {
    t,
    language,
  } as const;
}
