import { StoreValue } from 'antd/lib/form/interface';

export const normFile = (e: StoreValue) => {
  // console.log('Upload event:', e);
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

export const UPLOAD_MEDIA_MAX_FILE_SIZE_IN_GB = 5; // 5GB
export const UPLOAD_MEDIA_MAX_FILE_SIZE = UPLOAD_MEDIA_MAX_FILE_SIZE_IN_GB * 1024 * 1024 * 1024;
