import { RouteObject, RouterProvider, createHashRouter } from 'react-router-dom';
import mainRouter from '@router/mainRouter';
import { addIgnoredMessages, initIgnoreMessages } from '@common/utils/ignoreWarnings';
import BaseComponent from '@components/layouts/BaseComponent';

// Zustand  DEPRECTAED Warning 임시 제거.
addIgnoredMessages('warn', [
  'If you would like automatic filtering of unknown props, you can opt-into that behavior via',
]);

addIgnoredMessages('error', [
  'Did you mean', // svg fill-rule -> fillRule로 변경
  '`visible` is deprecated which will be removed in next major version, please use `open` instead.', // antd visible -> open props로 변경하라는 내용
  'validateDOMNesting', // <a> 태그 안에 또 다른 <a> 태그를 중첩해서 사용할 수 없다는 내용,
  'please use `open` instead.', // antd Dropdown, Modal, visible -> open props변경,
  'useForm', // useForm Instance 연결
  'overlay', // Dropdown overlay -> menu change 문구,
  '[antd: Form.Item] A `Form.Item` with a `name` prop must have a single child element.', // Form.Item name props 가지고 있을대 여러 개의 자식 요소를 포함하고 있을때 발생,
  'non-boolean', // DOM 요소의 속성(attribute)으로서의 boolean 값을 받지 말라는 경고
  'If you intentionally want it to appear in the DOM as a custom attribute, spell it as lowercase',
  'Unknown event handler property',
]);

// 무시 로직 초기화
initIgnoreMessages();

function App() {
  const routes: RouteObject[] = [
    {
      element: <BaseComponent />,
      children: mainRouter,
      path: '/',
    },
  ];

  return <RouterProvider router={createHashRouter(routes)} />;
}

export default App;
