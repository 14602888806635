import { Collapse, Menu } from 'antd';
import styled, { css } from 'styled-components';

export const StyledSideMenu = styled.div<{ isMenuDrawer: boolean; isEbay: boolean }>`
  background-color: #fff;
  width: ${(props) => (props.isMenuDrawer ? '256px' : '224px')};
  padding: 0 8px;
  ${(props) => (props.isMenuDrawer ? 'overflow-y: auto' : '')};
  overflow-x: hidden;
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .logo-phase-wrapper {
    display: flex;
    align-items: center;
    height: 60px;
  }

  .img-phase {
    margin-left: 4px;
  }

  .logo-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    padding-left: 20px;

    .logo {
      display: flex;
      align-items: center;
      height: ${(props) => (props.isEbay ? '29.41px' : '60px')};
      :after {
        display: inline-block;
        height: 100%;
        content: '';
        vertical-align: middle;
      }
      img {
        width: 100%;
        height: ${(props) => (props.isEbay ? '100%' : '')};
        max-width: 96px;
      }
    }
  }

  .group-name {
    padding-left: 24px;
    color: rgb(175, 175, 175);
    font-size: 12px;
    margin-top: 20px;
  }

  .language-dropdown {
    align-items: center;
    padding-top: 10px;
    padding-bottom: 20px;
    cursor: pointer;

    .ant-dropdown-menu {
      width: 90%;
      display: flex;
      justify-content: center;
    }

    .shoplive {
      vertical-align: sub;
      padding-left: 20px;
      margin-right: 10px;
    }
  }
`;

export const LanguageDropdownWrap = styled.div`
  .language-dropdown {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
  }

  .shoplive-icon {
    margin-right: 4px;
  }
`;

export const StyledPickerFrame = styled(Menu)`
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #fff;
  width: 90%;
  left: 5%;

  .radio-row {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    .ant-radio-wrapper {
      display: flex;
      justify-content: center;
    }
  }

  .language-title {
    flex-grow: 1;
    min-width: 45px;
    margin-right: 4px;
  }

  .ant-menu-item.ant-menu-item-selected {
    background: #f6f6f6;
  }
`;

export const StyledCollapseMenu = styled(Collapse)`
  padding: 0;
  .ant-collapse-header {
    padding: 0 !important;
  }
  .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding-top: 0;
    padding-bottom: 0;
    padding: 0;
    > div {
      padding-left: 40px;
    }
  }
  /* .ant-collapse-content-box {
    color: hsla(0, 0%, 56%, 1);
  } */
`;

export const StyledSideMenuItem = styled.div<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  min-height: 40px;
  padding: 4px 0;
  padding-left: 16px;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;

  a {
    width: 100%;
    display: flex;
    text-decoration: none;
    color: inherit;
    font-weight: inherit;
  }
  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 88%;
  }

  .wrap-children {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  svg {
    width: 20px;
    height: 20px;
    line-height: 1;
    vertical-align: middle;
    margin-right: 8px;

    path {
      fill: hsla(0, 0%, 56%, 1);
    }
  }

  ${({ selected }) =>
    selected &&
    css`
      background-color: hsla(0, 0%, 96%, 1);

      svg path {
        fill: #333;
      }
    `}

  &:hover {
    background-color: #f9f9fa;

    svg path {
      fill: #333;
    }
  }
`;

export const StyleLowModeContainer = styled.div`
  .low-mode-flex-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }
`;
