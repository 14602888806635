import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

import {
  AiClipUsageCoreSlice,
  createAiClipUsageCoreSlice,
  getAiClipUsageCoreSliceInitialState,
} from './createAiClipUsageCoreSlice';

export type AiClipUsageState = AiClipUsageCoreSlice & {
  resetAiClipUsageStore: () => void;
};

const getInitialState = () => {
  return {
    ...getAiClipUsageCoreSliceInitialState(),
  };
};

export const useAiClipUsageStore = createWithEqualityFn<AiClipUsageState>(
  (set, get) => ({
    ...createAiClipUsageCoreSlice(set, get),
    resetAiClipUsageStore: () => set(getInitialState()),
  }),
  shallow,
);
