import { t } from '@common/helper/languages';
import { Modal, ModalFuncProps } from 'antd';
import { useRef } from 'react';

// 더블클릭, Enter를 통해 1회만 동작해야할 OK가 여러번 동작되는 것을 방지.
const BLOCKING_MS = 500;

export const useConfirm = () => {
  const latestRef = useRef<number>(Date.now());

  const modalConfirm = (content: string, confirmProps?: ModalFuncProps) => {
    const onOkProp = confirmProps?.onOk;

    const onOk = (...args: any[]) => {
      if (Date.now() - latestRef.current > BLOCKING_MS) {
        latestRef.current = Date.now();
        onOkProp?.(args);
      }
    };

    return Modal.confirm({
      className: 'custom-modal-confirm',
      content: content ?? '',
      okText: confirmProps?.okText ?? t('button.confirm'),
      cancelText: confirmProps?.cancelText ?? t('button.cancel'),
      ...confirmProps,
      onOk,
    });
  };

  return { modalConfirm };
};
