// UUID dataUrl로 부터 파일 명 가져오기
const getFileName = (dataUrl: string) => {
  const dataUrlLength = dataUrl.length;
  const dataUrlLastSlash = dataUrl.lastIndexOf('/');
  return dataUrl.substring(dataUrlLastSlash + 1, dataUrlLength);
};

// A태그를 임의로 생성해서 download event 발생하고 지움
const handleDownloadByVirtualElement = (data: any, downloadName: string) => {
  // console.log(typeof data);
  // const url = URL.createObjectURL(new Blob(data, 'image/png'));
  if (data) {
    const elAnchor = document.createElement('a') as HTMLAnchorElement;
    elAnchor.style.display = 'none';
    elAnchor.href = data;
    elAnchor.download = downloadName;

    document.body.appendChild(elAnchor);
    elAnchor.click();
  }
};

export const PLACEHOLDER_IMAGE = 'https://resource.shoplive.cloud/images/admin/img/placeholder.jpg';
//마지막에 _wXXX 가 붙었는지 확인하는 정규식
// const regxWidthLast = /_w[0-9]{2,3}$/g;

export const getThumbnail = (originalUrl: any, width: string | number) => {
  if (typeof originalUrl !== 'string') {
    return PLACEHOLDER_IMAGE;
  } else if (originalUrl.trim() === '') {
    return PLACEHOLDER_IMAGE;
  }

  // 옛날에 만들어진 리소스라면 기존 리사이즈 로직을 태운다.
  if (originalUrl.indexOf('cloudfront.net') >= 0) {
    return originalUrl;
  }

  //이미지 url 입력시 url이 완전하지 않기 때문에 error 가 떨어지므로 try catch 추가
  try {
    const url = new URL(originalUrl.replace('resource.shoplive.cloud/images/', 'image.shoplive.cloud/'));
    url.searchParams.set('w', String(width));
    url.searchParams.set('from', 'admin');
    // 최신 image 람다 리소스는, 리사이즈 방식이 다르다.
    return url.toString();
  } catch (err) {
    // invalid url
    return '';
  }
};

// 업로드된 이미지 파일로 다운로드
export const downloadImageFile = (dataUrl: string) => {
  const xhr = new XMLHttpRequest();

  xhr.onload = () => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const downloadName = getFileName(dataUrl) || 'download_image.png';
      handleDownloadByVirtualElement(reader.result, downloadName);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open('GET', dataUrl);
  xhr.responseType = 'blob';
  xhr.onerror = (error) => {
    window.open(dataUrl);
  };
  xhr.send();
};
