import { TMainAdministrator } from 'types/administrator';

export const actionType = {
  SET_ADMINISTRATOR: 'SET_ADMINISTRATOR',
  SET_LANGUAGE: 'SET_LANGUAGE',
};

export const createAction = (type: string, payload?: any) => {
  return payload !== undefined ? { type, payload } : { type };
};

export const actions = {
  setAdministrator: (admin: TMainAdministrator | null) => createAction(actionType.SET_ADMINISTRATOR, admin),
  setLanguage: (language: string) => createAction(actionType.SET_LANGUAGE, language),
};
