import { useTranslation } from '@hooks/useTranslation';
import {
  AiClipMultiProgressType,
  useMultipProgressPopupStore,
} from '@store/multiProgressPopupStore/multiProgressPopupStore';
import {
  MultiProgressPopup,
  MultiProgressPopupStatus,
} from '@styles/design-system/multi-progress-popup/MultiProgressPopup';
import { omit } from 'lodash';
import React, { useEffect, useState } from 'react';
import { AiClipArchiveMultiProgressPopupItem } from './AiClipArchiveMultiProgressPopupItem';
import { AiClipMultiProgressPopupItem } from './AiClipMultiProgressPopupItem';
import { aiClipFlag } from '@components/ai-clip/ai-clip.constant';
import { cancelAiClipArchiveApi } from '@api/shoplive/ai-clip';

// {AiClipMultiProgressType}--{id}
type PopupStatusId = string;

type PopupStatusRecord = { [id: PopupStatusId]: MultiProgressPopupStatus };

function formatPopupStatusId(type: AiClipMultiProgressType, id: string): PopupStatusId {
  return `${type}__${id}`;
}

function validateStatuses(statuses: MultiProgressPopupStatus[]) {
  const totalCount = statuses.length;
  const successCount = statuses.filter((stauts) => stauts === MultiProgressPopupStatus.SUCCESS).length;
  const failedCount = statuses.filter((stauts) => stauts === MultiProgressPopupStatus.FAILED).length;

  return {
    isAllSuccess: totalCount === successCount,
    isAllFailed: totalCount === failedCount,
    successCount,
    failedCount,
    totalCount,
  } as const;
}

export const AiClipDownloadMultiProgressPopup: React.FC = () => {
  const [aiCilpDownloads, removeAiClipDownload, clearAiClipDownloads] = useMultipProgressPopupStore((state) => [
    state.aiCilpDownloads,
    state.removeAiClipDownload,
    state.clearAiClipDownloads,
  ]);
  const [popupStatusRecord, setPopupStatusRecord] = useState<PopupStatusRecord>({});

  const { t, language } = useTranslation();

  useEffect(() => {
    return () => {
      setPopupStatusRecord({});
    };
  }, []);

  const removeAiClip = (mp4ClipId: string) => {
    const type = AiClipMultiProgressType.AiClip;

    setPopupStatusRecord((prev) => omit(prev, [formatPopupStatusId(type, mp4ClipId)]));
    removeAiClipDownload({
      multiProgressType: type,
      mp4ClipId,
    });
  };
  const updateAiClipStatus = (mp4ClipId: string, popupStatus: MultiProgressPopupStatus) => {
    const type = AiClipMultiProgressType.AiClip;

    setPopupStatusRecord((prev) => ({
      ...prev,
      [formatPopupStatusId(type, mp4ClipId)]: popupStatus,
    }));
  };

  const removeAiClipArchive = ({
    aiClipId,
    aiClipArchiveId,
    isCancel,
  }: {
    aiClipId: string;
    aiClipArchiveId: string;
    isCancel?: boolean;
  }) => {
    const type = AiClipMultiProgressType.AiClipArchive;

    setPopupStatusRecord((prev) => omit(prev, [formatPopupStatusId(type, aiClipArchiveId)]));
    removeAiClipDownload({
      multiProgressType: type,
      aiClipArchiveId,
    });

    if (isCancel) {
      void cancelAiClipArchiveApi(aiClipId, aiClipArchiveId);
    }
  };
  const updateAiClipArchiveStatus = (aiClipArchiveId: string, popupStatus: MultiProgressPopupStatus) => {
    const type = AiClipMultiProgressType.AiClipArchive;

    setPopupStatusRecord((prev) => ({
      ...prev,
      [formatPopupStatusId(type, aiClipArchiveId)]: popupStatus,
    }));
  };

  const statuses = Object.values(popupStatusRecord);

  const { isAllSuccess, isAllFailed, successCount, failedCount, totalCount } = validateStatuses(statuses);

  const titleText = isAllSuccess
    ? t('multi_progress_popup.title.download_success')
    : isAllFailed
    ? t('multi_progress_popup.title.download_failed')
    : t('multi_progress_popup.title.download_ing');

  const [subtitleFirstParam, subtitleSecondParam] =
    language === 'en' ? [successCount, totalCount] : [totalCount, successCount];
  const subtitle = t('multi_progress_popup.subtitle.download_success_total', subtitleFirstParam, subtitleSecondParam);

  const subtitleText = `${subtitle}${failedCount > 0 ? ', ' : ''}`;
  const subtitleFailedText =
    failedCount > 0 ? t('multi_progress_popup.subtitle.download_failed_total', failedCount) : null;

  return (
    <MultiProgressPopup
      statuses={statuses}
      items={aiCilpDownloads.map((aiCilpDownload) => {
        if (aiCilpDownload.multiProgressType === AiClipMultiProgressType.AiClip) {
          return (
            <AiClipMultiProgressPopupItem
              key={aiCilpDownload.mp4ClipId}
              aiClipMp4Clip={aiCilpDownload}
              removeItem={removeAiClip}
              updatePopupStatus={updateAiClipStatus}
            />
          );
        }

        if (aiCilpDownload.multiProgressType === AiClipMultiProgressType.AiClipArchive) {
          return (
            <AiClipArchiveMultiProgressPopupItem
              key={aiCilpDownload.id}
              aiClipArchive={aiCilpDownload}
              removeItem={removeAiClipArchive}
              updatePopupStatus={updateAiClipArchiveStatus}
            />
          );
        }

        return null;
      })}
      texts={{
        title: aiClipFlag.preparingDownload ? t('multi_progress_popup.title.preparing') : titleText,
        subtitle: aiClipFlag.preparingDownload
          ? t('multi_progress_popup.subtitle.start_download_when_prepared')
          : subtitleText,
        subtitleFailed: subtitleFailedText,
        clearModalTitle: aiClipFlag.preparingDownload
          ? t('multi_progress_popup.modal.download_cancel_title')
          : t('multi_progress_popup.modal.download_clear_title'),
        clearModalContent: t('multi_progress_popup.modal.download_clear_content'),
      }}
      clearCallback={clearAiClipDownloads}
    />
  );
};
