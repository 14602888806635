import { sellersBriefListGet } from '@api/shoplive/sellers';
import { t } from '@common/helper/languages';
import { getThumbnail, PLACEHOLDER_IMAGE } from '@common/helper/thumbnail';
import { Select } from 'antd';
import styled from 'styled-components';
import { TSeller } from '@localTypes/seller';
import { ReactComponent as ArrowDownIcon } from '@assets/svg/arrow-down-s-line.svg';
import { useEffect, useRef } from 'react';

interface ISellerSelectBoxProps {
  sellerNoneTitle?: string;
  onSellerSelected: (seller: TSeller | null) => void;
  selectedSellerId: string | null;
  disabled?: boolean;
  isRevalidateOnClick?: boolean;
}

const SELLER_NONE_VALUE = 'NONE';

const StyledSellerSelect = styled(Select)`
  min-width: 160px;
  display: block;

  .ant-select-selector {
    border-radius: 5px !important;
    height: 36px !important;
    line-height: 36px !important;
    background-color: hsla(0, 0%, 100%, 1) !important;
    border: 1px solid hsla(0, 0%, 89%, 1) !important;
    padding: 0 12px !important;
    margin-top: 0 !important;
    position: relative;
  }
  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }
  .ant-select-arrow {
    width: 20px;
    height: 20px;
    top: 40%;
  }
  svg {
    //   background-color: pink;
    width: 20px;
    height: 20px;

    path {
      fill: hsla(0, 0%, 20%, 1);
    }
  }

  .seller-image {
    width: 24px;
    height: 24px;
    border-radius: 20px;
    overflow: hidden;
    object-fit: cover;
    margin-right: 4px;
  }
`;

const SellerSelectBox: React.FC<ISellerSelectBoxProps> = ({
  sellerNoneTitle = t('page.seller_selector.none'),
  onSellerSelected,
  selectedSellerId,

  disabled,
  isRevalidateOnClick,
}) => {
  const { data, mutate: revalidate } = sellersBriefListGet();

  const refSelect = useRef(null);

  useEffect(() => {
    const dom = document.querySelector('.seller-select-box input.ant-select-selection-search-input');
    dom?.setAttribute('autocomplete', 'one-time-code');
  }, [refSelect]);

  const sellers = data?.results || [];

  const filterOption = (input: string, option: any) => {
    if (option?.value === SELLER_NONE_VALUE) {
      return sellerNoneTitle.toLowerCase().includes(input.toLowerCase());
    }
    // sellers 배열에서 현재 옵션의 value에 해당하는 seller 찾기
    const correspondingSeller = sellers.find((seller) => seller.sellerId === option?.value);

    // 해당 seller의 name이 사용자의 입력값을 포함하는지 검사
    return correspondingSeller?.name.toLowerCase().includes(input.toLowerCase()) || false;
  };

  return (
    <StyledSellerSelect
      className="seller-select-box"
      showSearch
      value={selectedSellerId || SELLER_NONE_VALUE}
      disabled={disabled}
      suffixIcon={<ArrowDownIcon />}
      filterOption={filterOption}
      ref={refSelect}
      onChange={(value) => {
        const sellerObject = sellers.find((item) => item.sellerId === value);
        onSellerSelected(sellerObject || null);
      }}
      onClick={(e) => {
        if (isRevalidateOnClick) {
          revalidate();
        }
      }}
    >
      <Select.Option value={SELLER_NONE_VALUE}>{sellerNoneTitle}</Select.Option>
      {sellers.map((item) => (
        <Select.Option value={item.sellerId} key={item.sellerId} className="seller-select-option">
          <img className="seller-image" src={getThumbnail(item.profileUrl || PLACEHOLDER_IMAGE, 240)} />
          <span>{item.name}</span>
        </Select.Option>
      ))}
    </StyledSellerSelect>
  );
};

export default SellerSelectBox;
