import Error404Page from '@components/Error404Page';
import { lazy } from 'react';
const AiClipPage = lazy(() => import('@pages/ai-clip/AiClipPage'));
const AiClipUsagePage = lazy(() => import('@pages/ai-clip-usage/AiClipUsagePage'));
const AiClipPromptPage = lazy(() => import('@pages/ai-clip-prompt/AiClipPromptPage'));
const AiClipMonitorPage = lazy(() => import('@pages/ai-clip-monitor/AiClipMonitorPage'));
const CampaignMonitorPage = lazy(() => import('@pages/campaigns/CampaignMonitorPage'));
const CampaignInsightPage = lazy(() => import('@pages/insights/InSightsPage'));
const CampaignDetail2Page = lazy(() => import('@pages/campaigns2/Campaign2DetailPage'));
const CampaignDesktopPrompterPage = lazy(() => import('@pages/campaigns/CampaignDesktopPrompterPage'));
const CampaignMobilePrompterPage = lazy(() => import('@pages/campaigns/CampaignMobilePrompterPage'));
const Campaign2Page = lazy(() => import('@pages/campaigns2/Campaign2Page'));
const CampaignEventTracePage = lazy(() => import('@pages/eventTrace/CampaignEventTracePage'));
const LoginPage = lazy(() => import('@pages/login/LoginPage'));
const OauthLoginPage = lazy(() => import('@pages/login/OauthPage'));
const ForgotPasswordPage = lazy(() => import('@pages/login/ForgotPasswordPage'));
const LoginFailPage = lazy(() => import('@pages/login/LoginFailPage'));
const RenewPasswordPage = lazy(() => import('@pages/register/RenewPasswordPage'));
const SetupAdminPage = lazy(() => import('@pages/register/SetupAdminPage'));
const SkinsPage = lazy(() => import('@pages/skins/SkinsPage'));
const HostsPage = lazy(() => import('@pages/hosts/HostsPage'));
const BrandsPage = lazy(() => import('@pages/brands/BrandsPage'));
const GoodsPage = lazy(() => import('@pages/goods/GoodsPage'));
const PopupsPage = lazy(() => import('@pages/popups/PopupsPage'));
const MediasPage = lazy(() => import('@pages/medias/MediasPage'));
const MediasMonitorPage = lazy(() => import('@pages/medias/MediasMonitorPage'));
const SellersPage = lazy(() => import('@pages/sellers/SellersPage'));
const SellersDetailPage = lazy(() => import('@pages/sellers/SellersDetailPage'));
const ShortformsPage = lazy(() => import('@pages/shortforms/ShortformsPage'));
const ShortformDetailPage = lazy(() => import('@pages/shortforms/ShortformDetailPage'));
const ShortformJsonEditorPage = lazy(() => import('@pages/shortforms/ShortformJsonEditorPage'));
const ShortformSettingUIPage = lazy(() => import('@pages/shortforms/ShortformSettingUIPage'));
const LiveSettingUIPage = lazy(() => import('@pages/live/LiveSettingUIPage'));
const ShortformGroupPage = lazy(() => import('@pages/shortforms/ShortformGroupPage'));
const ShortformGroupDetailPage = lazy(() => import('@pages/shortforms/ShortformGroupDetailPage'));
const ShortformNotice11stPage = lazy(() => import('@pages/shortforms/ShortformNotice11stPage'));
const ShortformNotice11stDetailPage = lazy(() => import('@pages/shortforms/ShortformNotice11stDetailPage'));
const ShortformRankingPage = lazy(() => import('@pages/shortforms/ShortformRankingPage'));
const ShortformReviewPage = lazy(() => import('@pages/shortforms/ShortformReviewPage'));
const ShortformEventTracePage = lazy(() => import('@pages/eventTrace/ShortformEventTracePage'));

const PenaltiesPage = lazy(() => import('@pages/penalties/PenaltiesPage'));
const CustomersPage = lazy(() => import('@pages/customers/CustomersPage'));
const CustomersDetailPage = lazy(() => import('@pages/customers/CustomersDetailPage'));
const SettingsPage = lazy(() => import('@pages/settings/SettingsPage'));
const TemplateLivePage = lazy(() => import('@pages/template/TemplateLivePage'));
const TemplatePluginPage = lazy(() => import('@pages/template/TemplatePluginPage'));
const TemplateThemePage = lazy(() => import('@pages/template/TemplateThemePage'));
const TemplateShortformPage = lazy(() => import('@pages/template/TemplateShortformPage'));
const TemplateSdkLandingPage = lazy(() => import('@pages/template/TemplateSdkLandingPage'));
const SdkLandingSettingUIPage = lazy(() => import('@pages/sdk-landing/SdkLandingSettingUIPage'));
const PluginHomePage = lazy(() => import('@pages/plugin/PluginHomePage'));
const PluginJsonPage = lazy(() => import('@pages/plugin/PluginJsonPage'));
const PluginPipPage = lazy(() => import('@pages/plugin/PluginPipPage'));
const PluginSettingUIPage = lazy(() => import('@pages/plugin/PluginSettingUIPage'));
const BannedWordsPage = lazy(() => import('@pages/bannedWords/BannedWordsPage'));
const CustomerBannedWordsPage = lazy(() => import('@pages/customerWords/CustomerBannedWordsPage'));
const HostBannedWordsPage = lazy(() => import('@pages/hostBannedWords/HostBannedWordsPage'));
const CampaignConsolePage = lazy(() => import('@pages/campaigns/CampaignConsolePage'));
const UserSettingPage = lazy(() => import('@pages/user/UserSettingPage'));
const IeUnsupportedPage = lazy(() => import('@pages/ieUnsupported/IeUnsupportedPage'));
const StudioPage = lazy(() => import('@pages/studio/StudioPage'));
const ServersAutoScalingGroupsPage = lazy(() => import('@pages/servers/auto-scaling-groups/ManageServersPage'));
const ServersInstancePage = lazy(() => import('@pages/servers/instances/InstancesPage'));
const ServersServicePage = lazy(() => import('@pages/servers/service/ServicePage'));
const ServersStreamManagerPage = lazy(() => import('@pages/servers/stream-manager/StreamManagerPage'));
const ServersStreamWorkerPage = lazy(() => import('@pages/servers/stream-worker/StreamWorkerPage'));
const MediaConvertPage = lazy(() => import('@pages/servers/media-convert/index'));
const Cafe24Page = lazy(() => import('@pages/cafe24/Cafe24Page'));
const ShortformDataInsightPage = lazy(() => import('@pages/shortforms/ShortformsDataInsightPage'));
const ContentCreatorListPage = lazy(() => import('@pages/contentCreator/ContentCreatorListPage'));
const ContentCreatorDetailPage = lazy(() => import('@pages/contentCreator/ContentCreatorDetailPage'));
const Cafe24AppPage = lazy(() => import('@pages/cafe24app/Cafe24AppPage'));
const Cafe24ResultPage = lazy(() => import('@pages/cafe24result/Cafe24ResultPage'));
const AccountFunctionsPage = lazy(() => import('@pages/accountFunctions/AccountFunctionsPage'));
const OverallCDIPage = lazy(() => import('@pages/campaigns2/insights/OverallCDIPage'));
const SingleCDIPage = lazy(() => import('@pages/campaigns2/insights/SingleCDIPage'));
const DataUsageDashboardPage = lazy(() => import('@pages/data-usage-dashboard/DataUsageDashboardPage'));

export default [
  {
    path: '/campaigns2/:id/insight',
    element: <SingleCDIPage />,
  },

  {
    path: '/campaigns/insight',
    element: <OverallCDIPage />,
  },

  {
    path: '/ai-clip',
    element: <AiClipPage />,
  },
  {
    path: '/ai-clip/usage',
    element: <AiClipUsagePage />,
  },
  {
    path: '/ai-clip-prompt',
    element: <AiClipPromptPage />,
  },
  {
    path: '/ai-clip-monitor',
    element: <AiClipMonitorPage />,
  },
  {
    path: '/campaigns',
    element: <Campaign2Page />,
  },
  {
    path: '/campaigns/:id/console',
    element: <CampaignConsolePage />,
  },
  {
    path: '/campaigns/monitor',
    element: <CampaignMonitorPage />,
  },
  {
    path: '/campaigns/:id/insights',
    element: <CampaignInsightPage />,
  },
  {
    path: '/campaigns/:id/prompter',
    element: <CampaignDesktopPrompterPage />,
  },
  {
    path: '/campaigns/:id/prompter-mobile',
    element: <CampaignMobilePrompterPage />,
  },
  {
    path: '/campaigns2',
    element: <Campaign2Page />,
  },
  {
    path: '/campaigns2/:id',
    element: <CampaignDetail2Page />,
  },
  {
    path: '/campaigns/:id',
    element: <CampaignDetail2Page />,
  },
  {
    path: '/campaigns/eventTrace',
    element: <CampaignEventTracePage />,
  },
  {
    path: '/hosts',
    element: <HostsPage />,
  },
  {
    path: '/goods',
    element: <GoodsPage />,
  },
  {
    path: '/brands',
    element: <BrandsPage />,
  },
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/login/oauth',
    element: <OauthLoginPage />,
  },
  {
    path: '/login/forgot-password',
    element: <ForgotPasswordPage />,
  },
  {
    path: '/login/fail',
    element: <LoginFailPage />,
  },
  {
    path: '/skins',
    element: <SkinsPage />,
  },
  {
    path: '/popups',
    element: <PopupsPage />,
  },
  {
    path: '/medias',
    element: <MediasPage />,
  },
  {
    path: '/medias/monitor',
    element: <MediasMonitorPage />,
  },
  {
    path: '/sellers',
    element: <SellersPage />,
  },
  {
    path: '/sellers/:id',
    element: <SellersDetailPage />,
  },
  {
    path: '/short-forms',
    element: <ShortformsPage />,
  },
  {
    path: '/short-forms/json',
    element: <ShortformJsonEditorPage />,
  },
  {
    path: '/short-forms/settingUI',
    element: <ShortformSettingUIPage />,
  },
  {
    path: '/live/settingUI',
    element: <LiveSettingUIPage />,
  },
  {
    path: '/short-forms/:id',
    element: <ShortformDetailPage />,
  },
  {
    path: '/short-forms/insight',
    element: <ShortformDataInsightPage />,
  },
  {
    path: '/short-forms/group',
    element: <ShortformGroupPage />,
  },
  {
    path: '/short-forms/group/:id',
    element: <ShortformGroupDetailPage />,
  },
  {
    path: '/short-forms/notice',
    element: <ShortformNotice11stPage />,
  },
  {
    path: '/short-forms/notice/:id',
    element: <ShortformNotice11stDetailPage />,
  },
  {
    path: '/short-forms/ranking',
    element: <ShortformRankingPage />,
  },
  {
    path: '/short-forms/review',
    element: <ShortformReviewPage />,
  },
  {
    path: '/short-forms/eventTrace',
    element: <ShortformEventTracePage />,
  },

  {
    path: '/penalties',
    element: <PenaltiesPage />,
  },
  {
    path: '/customers',
    element: <CustomersPage />,
  },
  {
    path: '/customers/:id',
    element: <CustomersDetailPage />,
  },
  {
    path: '/settings',
    element: <SettingsPage />,
  },
  {
    path: '/bannedWords',
    element: <BannedWordsPage />,
  },
  {
    path: '/customerBannedWords',
    element: <CustomerBannedWordsPage />,
  },
  {
    path: '/hostBannedWords',
    element: <HostBannedWordsPage />,
  },
  {
    path: '/user/settings',
    element: <UserSettingPage />,
  },
  {
    path: '/ieUnsupported',
    element: <IeUnsupportedPage />,
  },
  {
    path: '/studio',
    element: <StudioPage />,
  },
  {
    path: '/register/renew-password',
    element: <RenewPasswordPage />,
  },
  {
    path: '/register/setup-admin',
    element: <SetupAdminPage />,
  },
  {
    path: '/servers/auto-scaling-groups',
    element: <ServersAutoScalingGroupsPage />,
  },
  {
    path: '/servers/instances',
    element: <ServersInstancePage />,
  },
  {
    path: '/servers/service',
    element: <ServersServicePage />,
  },
  {
    path: '/servers/stream-manager',
    element: <ServersStreamManagerPage />,
  },
  {
    path: '/servers/stream-worker',
    element: <ServersStreamWorkerPage />,
  },
  {
    path: '/servers/media-convert',
    element: <MediaConvertPage />,
  },
  {
    path: '/cafe24',
    element: <Cafe24Page />,
  },
  {
    path: '/plugin/home',
    element: <PluginHomePage />,
  },
  {
    path: '/plugin/pip',
    element: <PluginPipPage />,
  },
  {
    path: '/plugin/jsonEditor',
    element: <PluginJsonPage />,
  },
  {
    path: '/plugin/settingUI',
    element: <PluginSettingUIPage />,
  },
  {
    path: '/sdk-landing/settingUI',
    element: <SdkLandingSettingUIPage />,
  },
  {
    path: '/template/live',
    element: <TemplateLivePage />,
  },
  {
    path: '/template/plugin',
    element: <TemplatePluginPage />,
  },

  {
    path: '/template/shortforms',
    element: <TemplateShortformPage />,
  },
  {
    path: '/template/theme',
    element: <TemplateThemePage />,
  },
  {
    path: '/template/sdk-landing',
    element: <TemplateSdkLandingPage />,
  },
  {
    path: '/contentCreator',
    element: <ContentCreatorListPage />,
  },
  {
    path: '/contentCreator/:id',
    element: <ContentCreatorDetailPage />,
  },
  {
    path: '/cafe24app',
    element: <Cafe24AppPage />,
  },
  {
    path: '/cafe24result',
    element: <Cafe24ResultPage />,
  },
  {
    path: '/*',
    element: <Error404Page />,
  },
  {
    path: '/accountFunctions',
    element: <AccountFunctionsPage />,
  },
  {
    path: '/dataUsage',
    element: <DataUsageDashboardPage />,
  },
];
