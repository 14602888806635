import defaultFetch from '@common/libs/fetch';
import RegionManager from '@common/regions';
import useSWR from 'swr';
import { TSeller } from 'types/seller';
import qs from 'querystring';
import { TAdministrator } from 'types/administrator';
import { TListResponse } from '@localTypes/response';

export const sellerInsert = (data: any) => {
  return defaultFetch(`${RegionManager.apiEndpoint}/sellers`, 'POST', data);
};

export const sellerUpdate = (data: any) => {
  return defaultFetch(`${RegionManager.apiEndpoint}/sellers`, 'PUT', data);
};

export const sellersListGet = (query: any) => {
  const url = `${RegionManager.apiEndpoint}/sellers?${qs.encode(query)}`;
  return useSWR<TListResponse<TSeller>>(url);
};

export const sellerGet = (sellerId: string) => {
  return defaultFetch(`${RegionManager.apiEndpoint}/sellers/${sellerId}`);
};

export const sellerDelete = (sellerId: string) => {
  return defaultFetch(`${RegionManager.apiEndpoint}/sellers/${sellerId}`, 'DELETE');
};

export const sellerAdminsListGet = (sellerId: string) => {
  const url = `${RegionManager.apiEndpoint}/administrator/seller/${sellerId}`;
  return useSWR<TListResponse<TAdministrator>>(url);
};

export const sellerAdminInsert = (sellerId: string, data: any) => {
  const url = `${RegionManager.apiEndpoint}/administrator/seller/${sellerId}`;
  return defaultFetch(url, 'POST', data);
};

export const sellerAdminUpdate = (sellerId: string, data: any) => {
  const url = `${RegionManager.apiEndpoint}/administrator/seller/${sellerId}`;
  return defaultFetch(url, 'PUT', data);
};

export const sellerAdminDelete = (adminId: string, customerId: string) => {
  const url = `${RegionManager.apiEndpoint}/administrator/seller?adminId=${adminId}&customerId=${customerId}`;
  return defaultFetch(url, 'DELETE');
};

// 소속된 셀러 간단 목록 조회
export const sellersBriefListGet = () => {
  const url = `${RegionManager.apiEndpoint}/sellers/briefs`;
  return useSWR<TListResponse<TSeller>>(url);
};

export const changeCurrentSeller = (sellerId: string) => {
  const url = `${RegionManager.apiEndpoint}/administrator/seller/latest/${sellerId}`;
  return defaultFetch(url, 'PUT');
};

export const deleteCurrentSeller = () => {
  const url = `${RegionManager.apiEndpoint}/administrator/seller/latest`;
  return defaultFetch(url, 'DELETE');
};
