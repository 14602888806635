import { css } from 'styled-components';
import { colorScheme } from './design-system/color/color-scheme';
import { shadowScheme } from './design-system/shadow/shadow-scheme';
import { textScheme } from './design-system/text/text-scheme';

// ko기준으로 간다 (en letter 0px 차이)
// font-weight는 직접 지정해주는걸로 간다

const font = {
  h1: css`
    font-size: 48px;
    line-height: 67px;
  `,
  h2: css`
    font-size: 42px;
    line-height: 59px;
  `,
  h3: css`
    font-size: 36px;
    line-height: 44px;
  `,
  h4: css`
    font-size: 26px;
    line-height: 31px;
  `,
  h5: css`
    font-size: 22px;
    line-height: 31px;
  `,
  h6: css`
    font-size: 18px;
    line-height: 22px;
  `,
  subtitle1: css`
    font-size: 16px;
    line-height: 22px;
  `,
  subtitle2: css`
    font-size: 14px;
    line-height: 20px;
  `,
  body1: css`
    font-size: 16px;
    line-height: 22px;
  `,
  body2: css`
    font-size: 14px;
    line-height: 20px;
  `,
  caption: css`
    font-size: 12px;
    line-height: 17px;
  `,
  button_l: css`
    font-size: 14px;
    line-height: 16px;
  `,
  button_m: css`
    font-size: 13px;
    line-height: 15px;
  `,
  button_s: css`
    font-size: 12px;
    line-height: 14px;
  `,
  button_xs: css`
    font-size: 10px;
    line-height: 12px;
  `,
  input_l: css`
    font-size: 14px;
    line-height: 20px;
  `,
  input_s: css`
    font-size: 12px;
    line-height: 14px;
  `,
  input_xs: css`
    font-size: 10px;
    line-height: 12px;
  `,
  hint: css`
    font-size: 13px;
    line-height: 18px;
  `,
  tableHeaderM: css`
    font-size: 13px;
    line-height: 14px;
  `,
  tableHeaderS: css`
    font-size: 12px;
    line-height: 14px;
  `,
  tableBodyM: css`
    font-size: 14px;
    line-height: 15px;
  `,

  //new-base
  console_h1: css`
    font-size: 42px;
    line-height: 44px;
    font-weight: 500;
  `,
  console_h2: css`
    font-size: 28px;
    line-height: 32px;
    font-weight: 500;
  `,
  console_h3: css`
    font-size: 26px;
    line-height: 32px;
    font-weight: 500;
  `,
  console_h4: css`
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
  `,
  console_h5: css`
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  `,
  console_sub_heading: css`
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
  `,
  console_button_m: css`
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
  `,
  console_button_s: css`
    font-size: 13px;
    line-height: 18px;
    font-weight: 500;
  `,
  console_body_m: css`
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
  `,
  console_body_s: css`
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
  `,
  console_caption: css`
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
  `,

  /**
   * 2024-02-15
   * 몇몇 빠진 font 존재를 확인하였고, 타입 에러 해결을 위해 빈 값을 추가함.
   */
  body: css``,
  l: css``,
  input_m: css``,
  console_body: css``,
};

const color = {
  gray: {
    50: '#f6f6f6',
    100: '#eee',
    200: '#e2e2e2',
    300: '#cbcbcb',
    400: '#afafaf',
    500: '#757575',
    600: '#545454',
    700: '#333333',
    800: '#1f1f1f',
    900: '#141414',
  },
  primary: '#333',
  accent: '#ef3434',
  secondary: '#eee',
  alert_warning: '#dd6725',
  alert_negative: '#fa8c16',
  alert_positive: '#03703C',
  alert_destructive: '#E35456',
  white: '#fff',
  black: '#000',
  violet: {
    deep: '#7845FF', // 찐한 바이올렛
    light: '#F1ECFF', // 연한 바이올렛
  },
} as const;

export const deviceSize = {
  mobile_normal: '380px',
  mobile_large: '480px',
  tablet_mini: '760px',
  tablet: '1080px',
  laptop_small: '1280px',
  laptop: '1460px',
  desktop: '1600px',
} as const;

const flexCenter = css`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const theme = {
  mobile_normal: `@media (max-width: ${deviceSize.mobile_normal})`,
  mobile_large: `@media (max-width: ${deviceSize.mobile_large})`,
  tablet_mini: `@media (max-width: ${deviceSize.tablet_mini})`,
  tablet: `@media (max-width: ${deviceSize.tablet})`,
  laptop_small: `@media (max-width: ${deviceSize.laptop_small})`,
  laptop: `@media (max-width: ${deviceSize.laptop})`,
  desktop: `@media (min-width: ${deviceSize.desktop})`,
  color,
  font,
  flexCenter,

  /**
   * 2024-02-14
   * New Design System
   */
  newColor: colorScheme,
  shadow: shadowScheme,
  text: textScheme,
} as const;

export type ShopliveTheme = typeof theme;

export default theme;
