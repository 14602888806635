import { downloadAiClipArchiveApi, getAiClipArchiveProgressApi } from '@api/shoplive/ai-clip';
import { TAiClipArchive, TAiClipArchiveProgress } from '@localTypes/ai-clip';
import { MultiProgressPopupStatus } from '@styles/design-system/multi-progress-popup/MultiProgressPopup';
import { MultiProgressPopupItem } from '@styles/design-system/multi-progress-popup/MultiProgressPopupItem';
import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import { TMultiProgressAiClipArchive } from '@store/multiProgressPopupStore/multiProgressPopupStore';
import { useTranslation } from '@hooks/useTranslation';
import { downloadNamedFile } from '@common/utils/ai-clip';
import { aiClipFlag, AI_CLIP_DOWNLOAD_INTERVAL } from '@components/ai-clip/ai-clip.constant';

type Props = {
  aiClipArchive: TMultiProgressAiClipArchive;
  removeItem: ({
    aiClipId,
    aiClipArchiveId,
    isCancel,
  }: {
    aiClipId: string;
    aiClipArchiveId: string;
    isCancel?: boolean;
  }) => void;
  updatePopupStatus: (aiClipArchiveId: string, popupStatus: MultiProgressPopupStatus) => void;
};

export const AiClipArchiveMultiProgressPopupItem: React.FC<Props> = ({
  aiClipArchive,
  removeItem,
  updatePopupStatus,
}) => {
  const [popupStatus, setPopupStatus] = useState<MultiProgressPopupStatus>(MultiProgressPopupStatus.READY);
  const [percent, setPercent] = useState<number>(0);

  const { t } = useTranslation();

  useEffect(() => {
    updatePopupStatus(aiClipArchive.id, popupStatus);
  }, [popupStatus]);

  const { mutate: revalidateDownloadAiClipArchive } = useSWR<TAiClipArchive>(
    ['downloadAiClipArchiveApi', aiClipArchive.aiClipId, aiClipArchive.id],
    () => downloadAiClipArchiveApi(aiClipArchive.aiClipId, aiClipArchive.id),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      onSuccess: (data) => {
        if (data.convertStatus === 'COMPLETE') {
          downloadNamedFile(data.archiveUrl, aiClipArchive.fileName);

          if (aiClipFlag.preparingDownload) {
            removeItem({
              aiClipId: aiClipArchive.aiClipId,
              aiClipArchiveId: aiClipArchive.id,
            });
          } else {
            setPopupStatus(MultiProgressPopupStatus.SUCCESS);
          }
        } else {
          setPopupStatus(MultiProgressPopupStatus.IN_PROGRESS);
        }
      },
    },
  );

  const { data: aiClipArchiveProgress } = useSWR<TAiClipArchiveProgress>(
    popupStatus === MultiProgressPopupStatus.IN_PROGRESS
      ? ['getAiClipArchiveProgressApi', aiClipArchive.aiClipId, aiClipArchive.id]
      : null,
    () => getAiClipArchiveProgressApi(aiClipArchive.aiClipId, aiClipArchive.id),
    {
      refreshInterval: (latestData) => {
        const shouldRefresh = latestData?.convertStatus === 'READY' || latestData?.convertStatus === 'IN_PROGRESS';
        return shouldRefresh ? AI_CLIP_DOWNLOAD_INTERVAL : 0;
      },
      onSuccess: (data) => {
        setPercent(data.convertRate);

        if (data.convertStatus === 'COMPLETE') {
          revalidateDownloadAiClipArchive();
        } else if (data.convertStatus === 'FAILED') {
          setPopupStatus(MultiProgressPopupStatus.FAILED);
        }
      },
    },
  );

  const wrapRemoveItem = () => {
    removeItem({
      aiClipId: aiClipArchive.aiClipId,
      aiClipArchiveId: aiClipArchive.id,
      isCancel: true,
    });
  };

  return (
    <MultiProgressPopupItem
      label={`${aiClipArchive.fileName}.zip`}
      status={popupStatus}
      percent={percent}
      texts={{
        cancelConfirmTitle: t('multi_progress_popup.modal.download_cancel_title'),
        cancelCompleteToast: t('multi_progress_popup.toast.download_cancel_complete'),
      }}
      onRemoveItem={wrapRemoveItem}
      onRetryItem={revalidateDownloadAiClipArchive}
    />
  );
};
