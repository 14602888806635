import defaultFetch from '@common/libs/fetch';
import RegionManager from '@common/regions';
import { TCreditUsage, TUsageMainCategory, TUsageSubCategoriesByMain, TUsageSubCategory } from '@localTypes/usage';
import qs from 'querystring';

export const getUsageSubCategoriesByMain = (mainCategory: TUsageMainCategory): Promise<TUsageSubCategoriesByMain> =>
  defaultFetch(`${RegionManager.apiEndpoint}/usage/mainCategory/${mainCategory}/subCategory`, 'GET');

/**
 * 월별 사용량
 */
export const getCreditUsage = (
  mainCategory: TUsageMainCategory,
  subCategory: TUsageSubCategory['subCategory'],
  year: number,
  month: number,
): Promise<TCreditUsage> => {
  const url = new URL(`${RegionManager.apiEndpoint}/usage/mainCategory/${mainCategory}/subCategory/${subCategory}`);

  url.searchParams.set('year', `${year}`);
  url.searchParams.set('month', `${month}`);

  return defaultFetch(url.toString(), 'GET');
};

export const getUsageDataCampaign = (accessKey: string, type: 'campaign' | 'shortform', q?: any) => {
  const url = `${RegionManager.apiEndpoints.papiEndpoint}/v2/${accessKey}/usage/data/${type}?${qs.encode(q)}`;
  return defaultFetch(url, 'GET');
};
