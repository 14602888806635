import styled from 'styled-components';

export const StyledMultiLanguageStatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .multi-language-item {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 14px;
  }

  .success {
    color: rgb(33, 151, 84);
    font-weight: bold;
  }
`;
