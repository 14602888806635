import moment from 'moment';
import { RefObject } from 'react';
import { ShortsStatus, TClip, TMaskingClip } from '@localTypes/shortform';
import { HLS_OPTIONS } from './hls';
import { ShortformNoticeStatus } from '@localTypes/shortform-notice';
import { RangeMillis } from '@components/shared-video-edit/SharedVideoEditModal';

export const VIDEO_TYPE = {
  CLIP: 'CLIP', // 구간 선택했을때
  CLIP_SUM: 'CLIP_SUM', // 구간 선택되지 않은 경우, 편집된 구간 전체
  ORIGINAL: 'ORIGINAL', // 편집되지 않은 전체 영상
};
export const FRAME_INPUT = {
  TITLE: 'TITLE',
  START: 'START',
  END: 'END',
} as const;

//medias-list 탭에 쓰기 위한 const
export const MEDIA_TAB = {
  VIDEO: 'VIDEO',
  CAMPAIGN: 'CAMPAIGN',
};

// ms
export const MIN_TIME_GAP = 1000;
export const CLIP_TIME_GAP = 60000;
// handler 2개 - 24 px+ border 양쪽  = 6px
export const MIN_CLIP_SIZE = 30;
export const KEYFRAME_WIDTH = 36 as const;

export const SHORTFORM_EDIT = {
  KEYFRAME: {
    PORTRAIT: {
      WIDTH: 36,
      HEIGHT: 64,
    },
    LANDSCAPE: {
      WIDTH: 115.2,
      HEIGHT: 64,
    },
  },
};

export const SLIDER = {
  DEFAULT: 1,
  MIN: 1,
  STEP: 1,
};

export const getSecondFrom = (value: string) => {
  const [str1, str2, str3] = value.split(':');
  const val1 = +str1;
  const val2 = +str2;
  const val3 = +str3;

  // seconds
  if (!isNaN(val1) && isNaN(val2) && isNaN(val3)) {
    return val1;
  }
  // minutes * 60 + seconds
  if (!isNaN(val1) && !isNaN(val2) && isNaN(val3)) {
    return val1 * 60 + val2;
  }
  //hours * 3600 +  min * 60 + seconds
  if (!isNaN(val1) && !isNaN(val2) && !isNaN(val3)) {
    return val1 * 3600 + val2 * 60 + val3;
  }

  return 0;
};

/**
 *
 * @param videoDurationMs  비디오 총 길이 ms
 * @param step 현재 scale step
 * @param barCount 보여주면 하는 개수 bar count
 * @returns
 */
export const calcTimeBar = (videoDurationMs: number, step: number, barCount?: number) => {
  //간격이 대략적으로 맞아보이게 계산만 해둠. 기준은 없음
  const calc = videoDurationMs / 1000 / step / (barCount ?? 5);
  return Math.ceil(calc);
};

export const changeToHHMMSS = (ms: number, isShowMs?: boolean) => {
  const hours = Math.floor(ms / 1000 / 3600);
  const minutes = Math.floor(((ms / 1000) % 3600) / 60);
  const remainingSeconds = Math.floor((ms / 1000) % 60);

  // ms가 0으로 시작할땐 0으로 보여주어야 한다
  const remainingMs = ms % 1000 < 100 ? '0' : String(Math.floor(ms % 1000)).slice(0, 1);
  const remainder = isShowMs ? `.${remainingMs}` : '';
  return [hours, minutes, remainingSeconds].map((val) => (val < 10 ? `0${val}` : val)).join(':') + remainder;
};

// 소수점 한자리까지만 나오는 최종 포맷
export const getDecimalFormat = (milliseconds: number) => {
  return Math.trunc(milliseconds / 1000) + `.${String(milliseconds % 1000).slice(0, 1)}`;
};

export const getEditorWidth = (startTime: number, endTime: number, chunkWidth: number) => {
  return ((endTime - startTime) / 1000) * chunkWidth;
};

export const getEditorLeft = (startTime: number, chunkWidth: number) => {
  const calc = (startTime / 1000) * chunkWidth;

  return calc;
};

export const getCurrentTimeInSeconds = (offsetX: number, totalWidth: number, msVideoDuration: number) => {
  const videoDuration = msVideoDuration / 1000;
  const chunkWidth = totalWidth / videoDuration;
  const currentSecond = offsetX / chunkWidth;
  if (currentSecond <= 0) {
    return 0;
  }
  return currentSecond;
};

export const pauseEvent = (e: any) => {
  if (e.stopPropagation) e.stopPropagation();
  if (e.preventDefault) e.preventDefault();
  e.cancelBubble = true;
  e.returnValue = false;
  return false;
};

export const getValidatedBoxTimeArray = (
  sectionIndex: number,
  sectionTimeArray: TClip[] | RangeMillis[],
  startMs: number,
  endMs: number,
  timeGap: number,
  videoDuration: number,
) => {
  let finalStartMs = startMs;
  let finalEndMs = endMs;

  sectionTimeArray.forEach((_, idx) => {
    if (idx !== sectionIndex) {
      return;
    }

    //첫 구간의 시작시간은 0보다 작을 수 없다.
    if (startMs <= 0 && idx === 0) {
      finalStartMs = 0;
      finalEndMs = timeGap;
      return;
    }

    //다음 구간이 있는 경우
    //끝 시간은 다음 시간의 시작 시간보다 커질 수 없다.
    const nextSection = sectionTimeArray[idx + 1];
    if (nextSection && endMs >= nextSection.from) {
      finalStartMs = nextSection.from - timeGap;
      finalEndMs = nextSection.from > videoDuration ? videoDuration : nextSection.from;
      return;
    }

    //마지막 구간의 끝 시간은 영상 길이를 넘을 수 없다.
    if (endMs >= videoDuration) {
      finalStartMs = videoDuration - timeGap;
      finalEndMs = videoDuration;
      return;
    }

    // 이전 구간이 있는 경우
    // 시작시간은 이전 시간 끝보다 작아질 수 없다.
    const prevSection = sectionTimeArray[idx - 1];
    if (prevSection && startMs <= prevSection.to) {
      finalStartMs = prevSection.to;
      finalEndMs = prevSection.to + timeGap;
      return;
    }
  });

  return [finalStartMs, finalEndMs];
};

export const validateStartTime = (sectionIndex: number, sectionTimeArray: TClip[] | RangeMillis[], ms: number) => {
  sectionTimeArray.map((_, idx: number) => {
    if (idx === sectionIndex) {
      //첫 구간의 시작 시간은 0보다 작을 수 없다.
      if (ms <= 0 && idx === 0) {
        ms = 0;
      }

      // min gap 보다 작아질 수 없음
      if (sectionTimeArray[idx].to - ms < MIN_TIME_GAP) {
        ms = sectionTimeArray[idx].to - MIN_TIME_GAP;
      }

      //시작 시간은 끝 구간을 넘을 수 없다.
      if (ms >= sectionTimeArray[idx].to) {
        ms = sectionTimeArray[idx].to - MIN_TIME_GAP;
      }
      //이전 구간이 있을때 시작 시간은 이전 구간의 끝보다 이전일 수 없다.
      if (sectionTimeArray[idx - 1]) {
        if (ms <= sectionTimeArray[idx - 1].to) {
          ms = sectionTimeArray[idx - 1].to;
        }
      }
    }
  });
  return ms;
};

export const validateEndTime = (
  sectionIndex: number,
  sectionTimeArray: TClip[] | RangeMillis[],
  ms: number,
  videoDuration: number,
) => {
  sectionTimeArray.map((_, idx: number) => {
    if (idx === sectionIndex) {
      // 끝 시간은 영상 길이를 넣을 수 없다.
      if (ms >= videoDuration) {
        ms = videoDuration;
      }

      // min gap 보다 작아질 수 없음
      if (ms - sectionTimeArray[idx].from < MIN_TIME_GAP) {
        ms = sectionTimeArray[idx].from - MIN_TIME_GAP;
      }

      //끝 구간은 시작 시간 이전일 수 없다.
      if (ms <= sectionTimeArray[idx].from) {
        ms = sectionTimeArray[idx].from + MIN_TIME_GAP;
      }

      //다음 구간이 있을 때 끝 시간은 다음 구간의 시작 시간을 넘을 수 없다.
      if (sectionTimeArray[idx + 1]) {
        if (ms >= sectionTimeArray[idx + 1].from) {
          ms = sectionTimeArray[idx + 1].from;
        }
      }
    }
  });
  return ms;
};

export const getMaskingArray = (sectionTimeArray: TClip[], videoDuration: number) => {
  const newArray: TMaskingClip[] = [];

  //구간이 없을때는 전체를 마스킹함
  if (sectionTimeArray.length === 0) {
    newArray.push({
      from: 0,
      to: videoDuration,
    });
    return newArray;
  }

  sectionTimeArray.map((_, idx: number) => {
    //마지막 구간일때
    if (!sectionTimeArray[idx + 1]) {
      if (videoDuration - sectionTimeArray[idx].to === 0) {
        //처음이자 마지막이면
        if (!sectionTimeArray[idx - 1]) {
          newArray.push({
            from: 0,
            to: sectionTimeArray[idx].from,
          });
        }
        return;
      }
      newArray.push({
        from: sectionTimeArray[idx].to,
        to: videoDuration,
      });

      //그런데 처음일떄
      if (!sectionTimeArray[idx - 1]) {
        if (sectionTimeArray[idx].from === 0) {
          return;
        }
        // 앞에 구간
        newArray.push({
          from: 0,
          to: sectionTimeArray[idx].from,
        });
      }
      return;
    }

    //해당 구간이 처음일때
    if (!sectionTimeArray[idx - 1]) {
      //다음 구간이 있을때
      if (sectionTimeArray[idx + 1] && sectionTimeArray[idx].to !== sectionTimeArray[idx + 1].from) {
        newArray.push({
          from: sectionTimeArray[idx].to,
          to: sectionTimeArray[idx + 1].from,
        });
      }

      // 앞에 구간
      newArray.push({
        from: 0,
        to: sectionTimeArray[idx].from,
      });
      return;
    }

    const nextSectionStart = sectionTimeArray[idx + 1].from;

    //현재 구간과 다음 구간의 간격이 있을 때
    if (nextSectionStart - sectionTimeArray[idx].to !== 0) {
      newArray.push({
        from: sectionTimeArray[idx].to,
        to: nextSectionStart,
      });
    }
  });
  return newArray;
};

export const getSliderMaxBy = (videoDuration: number, width: number) => {
  if (videoDuration === 0) {
    return 1;
  }
  const chunkWidth = width / (videoDuration / 1000);
  const maxScale = Math.round(KEYFRAME_WIDTH / chunkWidth);
  //짧은 영상이라도 최대 2배까지는 확대될 수 있게
  return maxScale === 0 || maxScale === 1 ? 2 : maxScale;
};

export const diffInDays = (endAt: number | undefined) => {
  if (endAt) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const endAtDate = new Date(endAt);
    endAtDate.setHours(0, 0, 0, 0);
    const diffInTime = endAtDate.getTime() - today.getTime();
    return Math.ceil(diffInTime / (1000 * 60 * 60 * 24));
  }
  return Number.MAX_SAFE_INTEGER;
};

export const attachVideo = (
  videoSrc: string,
  videoRef: RefObject<HTMLVideoElement>,
  hlsRef: any,
  maxBufferLength: number = 10,
  maxMaxBufferLength: number = 30,
) => {
  if (!videoRef || !videoRef.current) {
    return;
  }

  if (window.Hls.isSupported()) {
    const hlsOption = {
      ...HLS_OPTIONS,
      maxBufferLength: maxBufferLength,
      maxMaxBufferLength: maxMaxBufferLength,
    };
    const hls = new window.Hls(hlsOption);

    hls.loadSource(videoSrc);
    hls.attachMedia(videoRef.current);
    hlsRef.current = hls;
  } else {
    videoRef.current.src = videoSrc;
  }
};

export const detachVideo = (hlsRef: any) => {
  const hls = hlsRef.current;
  if (hls) {
    hls.stopLoad();
    hls.detachMedia();
    hls.destroy();
    hlsRef.current = null;
  }
};

export const getNearestMinuteStepTime = (step: number) => {
  const currentTime = moment();
  const remainder = currentTime.minute() % step;
  const nearestTime = currentTime.add(step - remainder, 'minutes').startOf('minute');
  return nearestTime;
};

// 현재 시간에서 가장 가까이 있는 과거 시간(분)  - 10분 단위 기준
export const nearestPastTimeInTenMinuteIncrements = () => {
  const now = new Date();
  const minutes = now.getMinutes();
  const remainingMinutes = minutes % 10;
  const nearestFutureTime = new Date(now.getTime() - remainingMinutes * 60000);

  nearestFutureTime.setSeconds(0);
  nearestFutureTime.setMilliseconds(0);

  return nearestFutureTime;
};

// 현재 시간에서 가장 가까이 있는 미래 시간(분)  - 10분 단위 기준
export const nearestFutureTimeInTenMinuteIncrements = () => {
  const now = new Date();
  const minutes = now.getMinutes();
  const remainingMinutes = 10 - (minutes % 10);
  const nearestFutureTime = new Date(now.getTime() + remainingMinutes * 60000);

  nearestFutureTime.setSeconds(0);
  nearestFutureTime.setMilliseconds(0);

  return nearestFutureTime;
};

export const getDefaultStartDate = (): Date => {
  const currentTime = moment();
  const remainder = currentTime.minute() % 10;
  return currentTime.subtract(remainder, 'minutes').seconds(0).milliseconds(0).toDate();
};

export const getStartDate = (shortsStatus: string, startTime: Date): Date => {
  if (shortsStatus === ShortsStatus.RESERVED) {
    const baseDate = startTime ? new Date(startTime) : nearestFutureTimeInTenMinuteIncrements();
    return baseDate;
  } else if (!startTime) {
    // 10분 이전의 시간을 구한다.
    const currentTime = moment();
    const remainder = currentTime.minute() % 10;
    const pastTime = currentTime.subtract(remainder, 'minutes').seconds(0).milliseconds(0).toDate();
    return pastTime;
  } else {
    return moment(startTime).toDate();
  }
};

export const getStartDateForNotice = (status: ShortformNoticeStatus, startTime: Date): Date => {
  if (status === ShortformNoticeStatus.SCHEDULED) {
    const baseDate = startTime ? new Date(startTime) : nearestFutureTimeInTenMinuteIncrements();
    return baseDate;
  } else if (!startTime) {
    // 10분 이전의 시간을 구한다.
    const currentTime = moment();
    const remainder = currentTime.minute() % 10;
    const pastTime = currentTime.subtract(remainder, 'minutes').seconds(0).milliseconds(0).toDate();
    return pastTime;
  } else {
    return moment(startTime).toDate();
  }
};

//주어진 날짜를 포맷하고 기본 시간과 분을 설정
export const formatDateTimeWithDefault = (date: Date, defaultHours?: number, defaultMinutes?: number): Date => {
  const formattedDate = date;
  formattedDate.setHours(
    typeof defaultHours !== 'undefined' ? defaultHours : formattedDate.getHours() || parseInt(moment().format('HH')),
  );
  formattedDate.setMinutes(
    typeof defaultMinutes !== 'undefined'
      ? defaultMinutes
      : formattedDate.getMinutes() || parseInt(moment().format('mm')),
  );
  formattedDate.setSeconds(0);
  formattedDate.setMilliseconds(0);
  return formattedDate;
};

export const isFutureTime = (startTime: number) => {
  const currentTime = moment();
  const startDay = moment(startTime);
  return startDay.isAfter(currentTime);
};

export const isPastTime = (time: number) => {
  const currentTime = moment();
  const targetTime = moment(time);
  return targetTime.isBefore(currentTime);
};
