import React, { CSSProperties } from 'react';
import { RuleSet, css, styled } from 'styled-components';

export type SidebarBadgeType = 'BETA' | 'NEW';

type Props = {
  badge: SidebarBadgeType;
  style?: CSSProperties;
};

export const SidebarBadge: React.FC<Props> = ({ badge, style }) => {
  return (
    <StyledBadge style={style} $badge={badge}>
      {badge}
    </StyledBadge>
  );
};

const badgeStyleMap: Record<SidebarBadgeType, RuleSet<object>> = {
  BETA: css`
    border: 1px solid rgba(51, 51, 51, 0.2);
    background: hsla(0, 0%, 93%, 1);
    color: ${({ theme }) => theme.newColor.Black700};
  `,
  NEW: css`
    border: 1px solid rgba(115, 86, 191, 0.2);
    background: #f0eaff;
    color: #7356bf;
  `,
};

const StyledBadge = styled.span<{ $badge: SidebarBadgeType }>`
  display: inline-flex;
  align-items: center;
  padding-inline: 8px;
  height: 20px;
  border-radius: 64px;
  text-transform: uppercase;
  margin-left: 8px;

  ${({ theme }) => theme.text.CaptionS};
  ${({ $badge }) => badgeStyleMap[$badge]}
`;
