import styled from 'styled-components';
import grayCloseButton from '@assets/svg/gray-close-button.svg';
import mediaUploadFailBtn from '@assets/svg/media-upload-fail-btn.svg';
import mediaUploadSuccessBtn from '@assets/svg/media-upload-success-btn.svg';

export const StyledMultiUploadWrapper = styled.div<{
  isExpanded: boolean;
}>`
  display: flex;
  flex-direction: column;
  width: 360px;
  background: ${({ theme }) => theme.newColor.White};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.newColor.Black200};
  border-bottom: none;
  max-height: ${({ isExpanded }) => (isExpanded ? '290px' : '68px')};
  overflow: ${({ isExpanded }) => (isExpanded ? 'auto' : 'hidden')};
  height: auto;
  transition: max-height 0.3s ease;
  box-shadow: ${({ theme }) => theme.shadow.depth4};

  .item-container {
    max-height: ${({ isExpanded }) => (isExpanded ? '240px' : '0')};
    overflow: auto;
    transition: max-height 0.3s ease;
    overscroll-behavior: contain;
  }
  .ant-progress-inner {
    border-radius: 0;
  }

  .title-wrapper {
    display: flex;
    padding: 16px;
    height: 68px;
    border-bottom: 1px solid ${({ theme }) => theme.newColor.Black200};
    box-sizing: border-box;
  }

  .upload-text-wrapper {
    flex: 1;
  }
  .button-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

export const StyledMultiUploadItemWrapper = styled.div<{ rate: number }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  box-sizing: border-box;
  border-bottom: 1px solid #eee;
  width: 100%;
  height: auto;
  padding-block: 14px;
  position: relative;

  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
  }

  .progress {
    left: 0;
    width: ${({ rate }) => `${rate}%`};
    position: absolute;
    bottom: -1px;
    border-bottom: 2px solid black;
    transition: width 0.5s ease-in-out;
  }

  .item-title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 16px;
    .ant-spin {
      width: 24px;
      height: 24px;
    }
  }

  .name {
    font-size: 13px;
    font-family: 400;
    line-height: 18px;
  }
  .cancel-button {
    width: 30px;
    height: 30px;
    border: none;
    padding: 0;
  }

  .error-icon,
  .success-icon {
    display: block;
    width: 20px;
    height: 20px;
    min-width: 20px;
    height: 20px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .loading-icon,
  .error-icon,
  .success-icon {
    cursor: pointer;
  }

  .error-icon,
  .success-icon {
    &:hover {
      background-image: url(${grayCloseButton});
      width: 28px;
      height: 28px;
      background-size: 28px;
      background-repeat: no-repeat;
      background-size: contain;
      position: relative;
      right: -3px;
    }
  }

  .error-icon {
    background-image: url(${mediaUploadFailBtn});
  }

  .success-icon {
    background-image: url(${mediaUploadSuccessBtn});
  }
`;
