import styled from 'styled-components';
import { ReactComponent as WarningIcon } from '@assets/svg/error-warning-line.svg';
import { Button } from 'antd';
import { t } from '@common/helper/languages';
import { useNavigate } from 'react-router-dom';
import { FallbackProps } from 'react-error-boundary';

function ErrorFallback({ resetErrorBoundary }: FallbackProps) {
  const navigate = useNavigate();

  return (
    <StyledWrapper>
      <WarningIcon />
      <div className="main-text">{t('page.service_error.main')}</div>
      <div className="sub-text">{t('page.service_error.sub')}</div>
      <Button
        onClick={() => {
          navigate('/campaigns');
          resetErrorBoundary();
        }}
      >
        {t('page.service_go.home')}
      </Button>
    </StyledWrapper>
  );
}

export default ErrorFallback;

export const StyledWrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-color: hsla(0, 0%, 96%, 1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;

  svg path {
    fill: hsla(0, 0%, 56%, 1);
  }

  .main-text {
    font-size: 16px;
    font-weight: 600;
    color: hsla(0, 0%, 20%, 1);
  }
  .sub-text {
    color: hsla(0, 0%, 56%, 1);
    font-size: 14px;
    font-weight: 500;
  }
  .ant-btn {
    margin-top: 12px;
    font-size: 14px;
  }
`;
