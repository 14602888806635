export const colorScheme = {
  Red100: '#FFF1F1',
  Red200: '#FFDEDE',
  Red300: '#FFBEBE',
  Red400: '#FD9191',
  Red500: '#EA6565',
  Red600: '#EF3434',
  Red700: '#D72727',
  Red800: '#A21212',
  Red900: '#5F0808',
  Black50: '#F6F6F6',
  Black100: '#EEEEEE',
  Black200: '#E2E2E2',
  Black300: '#CBCBCB',
  Black400: '#AFAFAF',
  Black500: '#8F8F8F',
  Black600: '#545454',
  Black700: '#333333',
  Black800: '#1F1F1F',
  Black900: '#141414',
  Black: '#000000',
  White: '#FFFFFF',
  Purple100: '#F1ECFF',
  Purple200: '#E4DAFF',
  Purple300: '#D6C7FF',
  Purple400: '#BBA2FF',
  Purple500: '#936AFF',
  Purple600: '#7845FF',
  Purple700: '#6037CC',
  Purple800: '#482999',
  Purple900: '#301C66',
  DimBlack5: 'rgba(0, 0, 0, 0.05)',
  DimBlack10: 'rgba(0, 0, 0, 0.10)',
  DimBlack20: 'rgba(0, 0, 0, 0.20)',
  DimBlack40: 'rgba(0, 0, 0, 0.40)',
  DimBlack60: 'rgba(0, 0, 0, 0.60)',
  DimBlack70: 'rgba(0, 0, 0, 0.70)',
  DimBlack80: 'rgba(0, 0, 0, 0.80)',
  DimWhite5: 'rgba(255, 255, 255, 0.05)',
  DimWhite10: 'rgba(255, 255, 255, 0.10)',
  DimWhite20: 'rgba(255, 255, 255, 0.20)',
  DimWhite40: 'rgba(255, 255, 255, 0.40)',
  DimWhite60: 'rgba(255, 255, 255, 0.60)',
  DimWhite70: 'rgba(255, 255, 255, 0.70)',
  DimWhite80: 'rgba(255, 255, 255, 0.80)',
  AlertNegative: '#FA8C16',
  AlertPositive: '#219653',
  AlertDestructive: '#E35456',
  AlertBlue: 'hsla(219, 88%, 55%, 1)',
  AlertPurple: 'hsla(257, 45%, 54%, 1)',
  AlertBrown: 'hsla(1, 33%, 55%, 1)',
  AlertPink: 'hsla(338, 90%, 69%, 1)',
  Orange100: '#EEB041',
} as const;

export type ColorToken = keyof typeof colorScheme;
