import { TShortsValidateType } from '@localTypes/shortform';
import { StateCreator } from 'zustand';

type ShortformState = {
  isCreatorNeedValidate: boolean;
  setIsCreatorNeedValidate: (isWriterNeedValidate: boolean) => void;
  creatorValidateType: TShortsValidateType;
  setCreatorValidateType: (CreatorValidateType: TShortsValidateType) => void;
};

export const createCreatorSlice: StateCreator<ShortformState> = (set) => ({
  isCreatorNeedValidate: false,
  setIsCreatorNeedValidate: (isCreatorNeedValidate: boolean) =>
    set(() => ({
      isCreatorNeedValidate,
    })),
  creatorValidateType: 'DEFAULT',
  setCreatorValidateType: (creatorValidateType: TShortsValidateType) =>
    set(() => ({
      creatorValidateType,
    })),
});
