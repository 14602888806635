import { TAiClipArchive, TAiClipMp4Clip } from '@localTypes/ai-clip';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

export enum AiClipMultiProgressType {
  AiClip = 'AI_CLIP', // 클립 영상 (.mp4 / 합성된 영상이 아닌 일반 영상)
  AiClipArchive = 'AI_CLIP_ARCHIVE', // 클립 아카이브 (.zip)
}

export type TMultiProgressAiClip = TAiClipMp4Clip & {
  fileName: string; // e.g. `something.mp4`
  multiProgressType: AiClipMultiProgressType.AiClip;
};

export type TMultiProgressAiClipArchive = TAiClipArchive & {
  fileName: string; // e.g. `something.zip`
  multiProgressType: AiClipMultiProgressType.AiClipArchive;
};

type TMultiProgressAiClipDownload = TMultiProgressAiClip | TMultiProgressAiClipArchive;

type TRemoveAiClipDownload =
  | {
      multiProgressType: AiClipMultiProgressType.AiClip;
      mp4ClipId: TAiClipMp4Clip['mp4ClipId'];
    }
  | {
      multiProgressType: AiClipMultiProgressType.AiClipArchive;
      aiClipArchiveId: TAiClipArchive['id'];
    };

type State = {
  aiCilpDownloads: TMultiProgressAiClipDownload[];
};

type Actions = {
  resetMultipProgressPopupStore: () => void;

  // Ai Clip 다운로드 관련
  pushAiClipDownload: (aiClipDownload: TMultiProgressAiClipDownload) => void;
  removeAiClipDownload: (removeTarget: TRemoveAiClipDownload) => void;
  clearAiClipDownloads: () => void;
};

const initialState: State = {
  aiCilpDownloads: [],
};

export const useMultipProgressPopupStore = createWithEqualityFn<State & Actions>(
  (set, get) => ({
    ...initialState,
    resetMultipProgressPopupStore: () => {
      set(initialState);
    },

    // Ai Clip 다운로드 관련
    pushAiClipDownload: (aiClipDownload: TMultiProgressAiClipDownload) => {
      const { aiCilpDownloads } = get();
      set({ aiCilpDownloads: [...aiCilpDownloads, aiClipDownload] });
    },
    removeAiClipDownload: (removeTarget: TRemoveAiClipDownload) => {
      const { aiCilpDownloads } = get();

      let removedAiClipDownloads = [] as TMultiProgressAiClipDownload[];

      if (removeTarget.multiProgressType === AiClipMultiProgressType.AiClip) {
        removedAiClipDownloads = aiCilpDownloads.filter(({ multiProgressType, mp4ClipId }: any) => {
          if (multiProgressType !== AiClipMultiProgressType.AiClip) {
            return true;
          }

          return mp4ClipId !== removeTarget.mp4ClipId;
        });
      }

      if (removeTarget.multiProgressType === AiClipMultiProgressType.AiClipArchive) {
        removedAiClipDownloads = aiCilpDownloads.filter(({ multiProgressType, id }: any) => {
          if (multiProgressType !== AiClipMultiProgressType.AiClipArchive) {
            return true;
          }

          return id !== removeTarget.aiClipArchiveId;
        });
      }

      set({
        aiCilpDownloads: removedAiClipDownloads,
      });
    },
    clearAiClipDownloads: () => set({ aiCilpDownloads: [] }),
  }),
  shallow,
);
