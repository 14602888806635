import { match } from 'path-to-regexp';

export const doesPathMatchPatterns = (path: string, patterns: string[]): boolean => {
  // 해시태그와 쿼리 파라미터를 제거
  const cleanedPath = path.split('?')[0].replace(/^#/, '');

  return patterns.some((pattern) => {
    const cleanedPattern = pattern.replace(/^#/, '');
    return match(cleanedPattern, { decode: decodeURIComponent })(cleanedPath);
  });
};
