// ignoreWarnings.ts
type ConsoleMethod = 'warn' | 'error';

const ignoredMessages: Record<ConsoleMethod, string[]> = {
  warn: [],
  error: [],
};

export function addIgnoredMessages(method: ConsoleMethod, messages: string[]): void {
  ignoredMessages[method] = [...ignoredMessages[method], ...messages];
}

function overrideConsole(method: ConsoleMethod): void {
  const originalConsole = console[method];
  console[method] = function (...args: any[]) {
    if (
      !ignoredMessages[method].some((ignoredMessage) => typeof args[0] === 'string' && args[0].includes(ignoredMessage))
    ) {
      originalConsole.apply(console, args);
    }
  };
}

export function initIgnoreMessages(): void {
  overrideConsole('warn');
  overrideConsole('error');
}
