import { publicRuntimeConfig } from '../config';
import { PHASE, getApiEndpointByDomain, phase } from './utils/phase';

// stage 일때는, stg-admin.shoplive.cloud/api 를 그냥 사용해달라는 서버팀의 요청.
let apiEndpointOverride = publicRuntimeConfig.apiEndpointOverride;
if (!apiEndpointOverride && phase === PHASE.STG) {
  apiEndpointOverride = getApiEndpointByDomain();
}

const LOCAL_KEY_API_ENDPOINT = 'apiendpoint';
const LOCAL_KEY_AVAILABLE_REGIONS = 'avlbregions';

export interface IAdminApiEndpoint {
  title: string;
  region: string;
  adminApiUrl: string;
}

export interface IAdminApiEndpoints {
  adminEndpoint: string;
  apiEndpoint: string;
  papiEndpoint: string;
}

class RegionManagerClass {
  availableRegions: IAdminApiEndpoint[] = [];
  apiEndpoint = '';
  apiEndpoints = {
    adminEndpoint: '',
    apiEndpoint: '',
    papiEndpoint: '',
  };
  isEndpointFromRuntimeConfig = false;

  constructor() {
    // OVERRIDE ENDPOINT 가 있으면 무조건 ENDPOINT 로 지정.
    if (apiEndpointOverride) {
      this.apiEndpoint = apiEndpointOverride;
      return;
    }

    // 저장되어있는 apiEndpoint 가 있으면 로드해놓는다.
    this.apiEndpoint = localStorage.getItem(LOCAL_KEY_API_ENDPOINT) || '';

    if (!this.apiEndpoint) {
      this.apiEndpoint = getApiEndpointByDomain();
      this.isEndpointFromRuntimeConfig = true;
    }

    // 저장되어있는 regions 가 있으면 로드해놓는다.
    const regions = localStorage.getItem(LOCAL_KEY_AVAILABLE_REGIONS);
    if (regions) {
      this.availableRegions = JSON.parse(regions);
    }
  }

  saveAvailableRegions = (regions: IAdminApiEndpoint[]) => {
    this.availableRegions = regions || [];
    localStorage.setItem(LOCAL_KEY_AVAILABLE_REGIONS, JSON.stringify(this.availableRegions));
  };

  saveApiEndpoint = (endpoint: string) => {
    if (apiEndpointOverride) {
      return;
    }
    this.apiEndpoint = endpoint;
    localStorage.setItem(LOCAL_KEY_API_ENDPOINT, endpoint);
  };
  saveApiEndPoints = (endpoints: IAdminApiEndpoints) => {
    this.apiEndpoints = endpoints;
  };
}

const RegionManager = new RegionManagerClass();
export default RegionManager;
