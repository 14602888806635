import styled from 'styled-components';

const StyledShoplivePoweredMark = styled.div`
  #shoplive-powered-mark {
    background-color: #ffffff;
    box-shadow:
      0px 0px 2px rgba(0, 0, 0, 0.2),
      0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding: 7px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #757575;

    position: fixed;
    z-index: 1;
    bottom: 12px;
    bottom: calc(12px + env(safe-area-inset-bottom));
    right: 4px;

    .powered-string {
      flex-shrink: 0;
    }

    .powered-shoplive-logo {
      margin-left: 3px;
    }

    ${({ theme }) => theme.mobile_large} {
      flex-direction: column;

      .powered-shoplive-logo {
        margin-left: 0px;
        margin-top: 3px;
      }
    }
  }
`;

const ShoplivePoweredMark = () => {
  return (
    <StyledShoplivePoweredMark>
      <div id="shoplive-powered-mark">
        <div className="powered-string">Powered by</div>
        <img
          className="powered-shoplive-logo"
          src="https://resource.shoplive.cloud/images/c35dc937-6966-45c2-96a0-3b91df698041.svg"
        />
      </div>
    </StyledShoplivePoweredMark>
  );
};

export default ShoplivePoweredMark;
