import { createWithEqualityFn } from 'zustand/traditional';
import { createShortFormEditSlice } from './createShortFormEditSlice';
import { createShortFormDetailSlice } from './createShortFormDetailSlice';
import { createCreatorSlice } from './createCreatorSlice';
import { createShortFormGroupSlice } from './createShortFormGroupSlice';
import { shallow } from 'zustand/shallow';

type StateFromFunctions<T extends [...any]> = T extends [infer F, ...infer R]
  ? F extends (...args: any) => object
    ? StateFromFunctions<R> & ReturnType<F>
    : unknown
  : unknown;

type State = StateFromFunctions<
  [
    typeof createShortFormEditSlice,
    typeof createShortFormDetailSlice,
    typeof createCreatorSlice,
    typeof createShortFormGroupSlice,
  ]
>;

const createStore = (set: any, get: any, api: any) => ({
  ...createShortFormGroupSlice(set, get, api),
  ...createShortFormEditSlice(set, get, api),
  ...createShortFormDetailSlice(set, get, api),
  ...createCreatorSlice(set, get, api),
});

export const useShortFormStore = createWithEqualityFn<State>(createStore, shallow);
