import React, { ReactNode, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Drawer, Typography } from 'antd';
import styled from 'styled-components';
import SideMenu from '@components/sidemenu/side-menu';
import MainHeader from '@components/layouts/main-header';
import ShoplivePoweredMark from './shoplive-powered-mark';
import { useAuthorityState } from '@context/authority/AuthorityContext';
import { useRouter } from '@hooks/useRouter';
import { MultiProgressPopupGroup } from '@components/layouts/MultiProgressPopupGroup';

export interface IDefaultLayoutProps {
  children: ReactNode;
}

export interface IBreadcrumb {
  title: string;
  key?: string;
  link?: string;
}

export const HeaderTitle = styled(Typography.Title)`
  font-size: 16px;
`;

const LayoutWrapper = styled.div`
  min-height: 100vh;
  background-color: rgb(246, 246, 246);

  .site-layout {
    // drawer menu width 랑 동일해야함
    // StyledSideMenu 컴포넌트 확인
    padding-left: 224px;

    .ant-page-header {
      box-shadow: none;
      border: 1px solid #f0f0f0;
      border-bottom: none;
    }
  }

  ${(props) => props.theme.tablet_mini} {
    .side-menu-bar {
      display: none;
    }

    .site-layout {
      padding-left: 0;
    }
  }
`;

const StyledMenuDrawer = styled(Drawer)`
  .ant-drawer-content-wrapper {
    width: 256px !important;
    padding: 0;
  }
`;

const DefaultLayout: React.FunctionComponent<IDefaultLayoutProps> = (props) => {
  const { children } = props;

  const { showPoweredBy } = useAuthorityState();

  const [isShowDrawer, setIsShowDrawer] = useState(false);

  const router = useRouter();

  const title = useMemo(() => {
    switch (router.pathname) {
      case '/campaigns':
      case '/campaigns2':
        return 'Campaign List';
      case '/campaigns/:id':
      case '/campaigns2/:id':
        return 'Campaign';
      case '/campaigns/:id/console':
      case '/campaigns2/:id/console':
        return 'Console';
      case '/campaigns/:id/prompter':
      case '/campaigns2/:id/prompter':
        return 'Prompter';
      case '/campaigns/:id/insights':
      case '/campaigns2/:id/insights':
        return 'Data Insights';
      case '/skins':
        return 'Logo';
      case '/hosts':
        return 'Hosts';
      case '/goods':
        return 'Products';
      case '/popups':
        return 'Pop-ups';
      case '/brands':
        return 'Brands';
      case '/medias':
        return 'Media';
      case '/settings':
        return 'Settings';
      case '/penalties':
        return 'Banned users';
      case '/bannedWords':
      case '/customerBannedWords':
      case '/hostBannedWords':
        return 'Banned words';
      case '/customers':
        return 'Customers';
      case '/login':
        return 'Login';
      case '/ai-clip':
        return 'AI Clip';
      default:
        return 'Shoplive';
    }
  }, [router.pathname]);

  const closeDrawer = () => {
    setIsShowDrawer(false);
  };

  return (
    <LayoutWrapper>
      <StyledMenuDrawer placement="left" onClose={closeDrawer} visible={isShowDrawer}>
        <SideMenu isMenuDrawer={true} />
      </StyledMenuDrawer>

      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="side-menu-bar">
        <SideMenu isMenuDrawer={false} />
      </div>
      <div className="site-layout">
        <MainHeader setIsShowDrawer={setIsShowDrawer} />
        {children}
      </div>
      {showPoweredBy && <ShoplivePoweredMark />}
      <MultiProgressPopupGroup />
    </LayoutWrapper>
  );
};

export default DefaultLayout;
