import { StoreApi } from 'zustand';
import { AiClipUsageState } from '.';

type AiClipUsageCoreState = {
  thisMonthCreditUsageSeconds: number;
};

type AiClipUsageCoreActions = {
  setThisMonthCreditUsageSeconds: (seconds: number) => void;
};

export type AiClipUsageCoreSlice = AiClipUsageCoreState & AiClipUsageCoreActions;

export const getAiClipUsageCoreSliceInitialState = (): AiClipUsageCoreState => {
  return {
    thisMonthCreditUsageSeconds: 0,
  };
};

export const createAiClipUsageCoreSlice = (
  set: StoreApi<AiClipUsageState>['setState'],
  get: StoreApi<AiClipUsageState>['getState'],
) => ({
  ...getAiClipUsageCoreSliceInitialState(),

  setThisMonthCreditUsageSeconds: (seconds: number) =>
    set({
      thisMonthCreditUsageSeconds: seconds,
    }),
});
