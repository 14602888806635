import React, { CSSProperties, ReactElement } from 'react';
import styled, { useTheme } from 'styled-components';
import { ColorToken } from '../color/color-scheme';
import { TextToken } from './text-scheme';
import { isString } from 'lodash';

export type TextProps = {
  variant: TextToken;
  children: React.ReactNode;
  /**
   * @default '#000000'
   */
  color?: ColorToken;
  bold?: boolean; // 굵게
  italic?: boolean; // 기울임
  underline?: boolean; // 밑줄
  strikethrough?: boolean; // 취소선
  hasTooltip?: boolean; // 툴팁 표시 여부 (HTML title attribute)
  /**
   * If set to 'true', the `<Text>` component will be rendered as `<span>` element, not `<p>`.
   * @default false
   */
  span?: boolean;
  style?: CSSProperties;
} & React.HtmlHTMLAttributes<HTMLParagraphElement | HTMLSpanElement>;

/**
 * @returns `underline` or `line-through` or `underline line-through`
 */
function getTextDecorationLine(underline: boolean, strikethrough: boolean): CSSProperties['textDecorationLine'] {
  const textDecorationLineValues = [underline ? 'underline' : null, strikethrough ? 'line-through' : null];

  return underline || strikethrough ? textDecorationLineValues.filter(Boolean).join(' ') : undefined;
}

export const Text = ({
  variant,
  children,
  color,
  bold = false,
  italic = false,
  underline = false,
  strikethrough = false,
  hasTooltip = false,
  span = false,
  style = {},
  ...rest
}: TextProps & React.HTMLAttributes<HTMLParagraphElement>): ReactElement => {
  const theme = useTheme();

  // The color of style prop is highest priority.
  const { color: colorFromStyle, ...styleWithoutColor } = style;
  const textColor = color ? theme.newColor[color] : '#000000';
  const finalTextColor = colorFromStyle ?? textColor;

  return (
    <S_Host
      style={{
        color: finalTextColor,
        fontWeight: bold ? 700 : undefined,
        fontStyle: italic ? 'italic' : undefined,
        textDecorationLine: getTextDecorationLine(underline, strikethrough),
        ...styleWithoutColor,
      }}
      as={span ? 'span' : undefined}
      $variant={variant}
      data-text={variant} // Set data-text attribute for better distinction in browser 'Elements' tab
      title={hasTooltip && isString(children) ? children : undefined}
      {...rest}
    >
      {children}
    </S_Host>
  );
};

const S_Host = styled.p<{ $variant: TextToken }>`
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  ${({ theme, $variant }) => theme.text[$variant]}
`;
