import styled from 'styled-components';
import { ReactComponent as WarningIcon } from '@assets/svg/error-warning-line.svg';
import { ReactComponent as GoHomeIcon } from '@assets/svg/Line__Systems--arrow-left-line.svg';
import { Button } from 'antd';

function Error404Page() {
  return (
    <StyledWrapper>
      <WarningIcon width={40} height={40} />
      <div className="main-text">something went wrong</div>
      <div className="sub-text">please try again.</div>
      <Button
        onClick={() => {
          window.location.href = '/';
        }}
      >
        <GoHomeIcon />
        <div className="text">Go Home</div>
      </Button>
    </StyledWrapper>
  );
}

export default Error404Page;

export const StyledWrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-color: hsla(0, 0%, 96%, 1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;

  svg path {
    fill: hsla(0, 0%, 56%, 1);
  }

  .main-text {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
  }
  .sub-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  .ant-btn {
    margin-top: 48px;
    width: 200px;
    height: 40px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;

    svg path {
      fill: hsla(0, 0%, 20%, 1);
    }

    .text {
      font-weight: 500;
      font-size: 14px;
    }
  }
`;
