import styled from 'styled-components';
import { Layout } from 'antd';

export const StyledTopHeader = styled(Layout.Header)`
  height: 60px;
  background: #fff;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.05);
  border-left: 1px solid #f6f6f6;
  padding: 0 24px;
  z-index: 2;
  display: flex;
  justify-content: end;
  align-items: center;
`;

export const StyledWrapAccountMenu = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 8px;
  height: 60px;

  ${(props) => props.theme.tablet_mini} {
    justify-content: space-between;
  }

  .inner-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }

  .region-selector {
    min-width: 160px;
    height: 36px !important;
    .ant-select-selector {
      border-radius: 5px;
      height: 100%;
    }
    .ant-select-selection-item {
      display: flex;
      align-items: center;
      height: 36px !important;
    }

    .ant-select-arrow {
      width: 20px;
      height: 20px;
      top: 40%;
      svg {
        width: 20px;
        height: 20px;

        path {
          fill: hsla(0, 0%, 20%, 1);
        }
      }
    }
  }

  .seller-select-box {
    .ant-select-selector {
      border: none;
      height: 30px;
      margin-top: 4px;
      background-color: #f5f5f5;
    }
  }

  ${(props) => props.theme.tablet} {
    .region-selector {
      min-width: 60px;
    }

    .seller-select-box {
      flex-shrink: 1;
      min-width: 70px;
    }
  }

  .drawer-button {
    // 클릭 영역을 패딩으로 늘리되 하단의 글과 얼라인 시키기 위해 마진으로 움직인만큼 마이너스 마진으로 되돌리기
    width: 40px;
    margin-left: -10px;
    display: none;
    vertical-align: sub;
    padding: 12px;
    cursor: pointer;
    ${(props) => props.theme.tablet_mini} {
      display: block;
    }
  }

  .ant-select-selection-item {
    color: #bbb;
  }
`;

export const StyledAccountName = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  margin-left: 8px;

  .profile {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 36px;
    overflow: hidden;

    .name {
      color: hsla(0, 0%, 0%, 1);
      margin-left: 8px;
      transition: 0.3s;
      font-size: 14px;
      &:hover {
        color: hsla(0, 0%, 60%, 1);
      }
    }
  }
  ${(props) => props.theme.tablet_mini} {
    .name {
      display: none;
    }
  }
`;
