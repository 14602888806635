import moment from 'moment';
import 'moment-timezone';

import { t } from './languages';

let currentAbbr = '';

export const getCurrentAbbr = () => {
  return currentAbbr;
};
export const setCurrentAbbr = (abbr: string) => {
  currentAbbr = abbr;
};

export const setCurrentTimezone = (timezone: string) => {
  moment.tz.setDefault(timezone);
};

/**
 *
 * @param date
 * @param format
 * @description
 * return YYYY-MM-DD HH:mm
 */

export const format_date = (date: Date | string | number, format: string, lang?: string) => {
  if (lang) {
    return moment(date).locale(lang).format(format);
  }
  return moment(date).format(format);
};

const TIME_FORMAT = 'HH:mm';
const DATE_FORMAT = {
  full: 'YYYY-MM-DD (ddd)',
  simple: 'YYYY-MM-DD',
  short: 'MM-DD',
};
export const dateFormat = (
  date: Date | string | number,
  formatType: 'full' | 'simple' | 'short',
  lang: string,
  withTime = true,
) => {
  if (!date) {
    return '';
  }

  //한국어 아니면 다 영어로.
  let format = DATE_FORMAT[formatType];
  if (withTime) {
    format = `${format} ${TIME_FORMAT}`;
  }
  const result = moment(date).locale(lang).format(format);
  return withTime ? `${result} ${currentAbbr}` : result;
};

export const getDday = (targetTime: number) => {
  const today = new Date().getTime();
  const gap = targetTime - today;
  const day = Math.ceil(gap / (1000 * 60 * 60 * 24));
  const hour = Math.ceil((gap % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const min = Math.ceil((gap % (1000 * 60 * 60)) / (1000 * 60));
  const sec = Math.ceil((gap % (1000 * 60)) / 1000);
  return { day, hour, min, sec };
};

//시간이  있으면 hh:mm:dd , 시간이 없으면 mm:dd 로 리턴.
export const getTimeDurationText = (time: number) => {
  const date = new Date(time);
  const hours = time > 3600000 ? Math.floor(time / 3600000) : 0;
  const min = date.getUTCMinutes();
  const sec = date.getUTCSeconds();
  let currentTimeStr = hours > 0 ? `${hours}:` : '';
  currentTimeStr += min > 9 ? `${min}:` : `0${min}:`;
  currentTimeStr += sec > 9 ? `${sec}` : `0${sec}`;
  return currentTimeStr;
};

export const getElapsedTime = (from: Date) => {
  const elapsedYears = moment.duration(moment().diff(moment(from))).years();
  const elapsedMonths = moment.duration(moment().diff(moment(from))).months();
  const elapsedDays = moment.duration(moment().diff(moment(from))).days();
  const elapsedHours = moment.duration(moment().diff(moment(from))).hours();
  const elapsedMinutes = moment.duration(moment().diff(moment(from))).minutes();

  return (
    (elapsedYears !== 0 ? `${elapsedYears}${t('date_unit.years')} ` : '') +
    (elapsedMonths !== 0 ? `${elapsedMonths}${t('date_unit.months')} ` : '') +
    (elapsedDays !== 0 ? `${elapsedDays}${t('date_unit.days')} ` : '') +
    (elapsedHours !== 0 ? `${elapsedHours.toString().padStart(2, '0')}${t('date_unit.hours')} ` : '') +
    `${elapsedMinutes.toString().padStart(2, '0')}${t('date_unit.minutes')}`
  );
};

export const getNextHour = () => {
  const now = new Date();
  const hours = now.getHours();

  now.setHours(hours + 1);
  now.setMinutes(0);
  now.setSeconds(0);
  now.setMilliseconds(0);

  return now;
};
