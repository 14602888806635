import { phase, PHASE } from './utils/phase';

export const getPhaseImageSrc = () => {
  switch (phase) {
    case PHASE.LOCAL:
      return 'https://resource.shoplive.cloud/images/admin/svg/label-main-header-local.svg';
    case PHASE.DEV:
      return 'https://resource.shoplive.cloud/images/admin/svg/label-main-header-dev.svg';
    case PHASE.QA:
      return 'https://resource.shoplive.cloud/images/admin/svg/label-main-header-qa.svg';
  }

  return null;
};

export default getPhaseImageSrc;
