import { StateCreator } from 'zustand';

type ShortformGroupState = {
  revalidateType: 'NORMAL' | 'REVALIDATE';
  setRevalidateType: (revalidateType: 'NORMAL' | 'REVALIDATE') => void;
};

export const createShortFormGroupSlice: StateCreator<ShortformGroupState> = (set) => ({
  revalidateType: 'NORMAL',
  setRevalidateType: (revalidateType: 'NORMAL' | 'REVALIDATE') =>
    set(() => ({
      revalidateType,
    })),
});
