import React, { useState } from 'react';
import { Tooltip, Spin } from 'antd';
import { StyledMultiUploadItemWrapper } from './styled';
import { FileData, useVideoUploadStore } from '@store/videoStore';
import { LoadingOutlined } from '@ant-design/icons';
import { ReactComponent as CancelButton } from '@assets/svg/gray-close-button.svg';
import { t } from '@common/helper/languages';

interface IMultiUploadItem {
  item: FileData;
  onClickCancelUpload: (item: FileData) => void;
}

export const MultiUploadItem: React.FC<IMultiUploadItem> = ({ item, onClickCancelUpload }) => {
  const [isHovered, setIsHovered] = useState(false);
  const { uploadQueue } = useVideoUploadStore();
  const queueUid = uploadQueue?.[0]?.uid ?? '';

  const antIcon = isHovered ? (
    <CancelButton style={{ width: 28, height: 28 }} />
  ) : (
    <LoadingOutlined style={{ fontSize: 20 }} spin />
  );

  const noIcon = item.uid !== queueUid && item.status === 'LOADING';

  const showIcon = () => {
    if (noIcon) return;

    if (item.status === 'LOADING') {
      return (
        <span
          className="loading-icon"
          onClick={() => onClickCancelUpload(item)}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <Spin indicator={antIcon} />
        </span>
      );
    }
    return (
      <span
        className={item.status === 'SUCCESS' ? 'success-icon' : 'error-icon'}
        onClick={() => onClickCancelUpload(item)}
      />
    );
  };

  return (
    <StyledMultiUploadItemWrapper rate={item.rate}>
      <div className="item-title-wrapper">
        <span className="name">{item.name}</span>
        <Tooltip
          placement="top"
          title={item.status === 'LOADING' ? t('page.media.upload_cancel') : t('page.media.upload_remove_from_list')}
          color="#333"
          overlayInnerStyle={{ borderRadius: 6, overflow: 'hidden' }}
          trigger={['hover']}
          open={noIcon ? false : undefined}
        >
          <div className="icon-wrapper">{showIcon()}</div>
        </Tooltip>
      </div>
      {item.status === 'LOADING' && item.rate > 0 && <div className="progress" />}
    </StyledMultiUploadItemWrapper>
  );
};
