import { getCreditUsage, getUsageSubCategoriesByMain } from '@api/shoplive/usage';
import { TCreditUsage, TUsageSubCategoriesByMain } from '@localTypes/usage';
import { useAiClipUsageStore } from '@store/aiClipUsageStore';
import { isUndefined } from 'lodash';
import { useCallback } from 'react';
import useSWRImmutable from 'swr/immutable';

function getThisMonth(): {
  year: number;
  month: number;
} {
  const now = new Date();

  return {
    year: now.getFullYear(),
    month: now.getMonth() + 1, // The return value of `getMonth()` starts from 0.
  };
}

function sumMonthCreditUsage(creditUsages: TCreditUsage['creditUsages']) {
  return creditUsages.reduce((acc, { usage }) => acc + parseInt(usage), 0);
}

export function useSWRGetAiClipThisMonthCreditUsage() {
  const [thisMonthCreditUsageSeconds, setThisMonthCreditUsageSeconds] = useAiClipUsageStore((state) => [
    state.thisMonthCreditUsageSeconds,
    state.setThisMonthCreditUsageSeconds,
  ]);

  const { data: usageSubCategoriesByMain } = useSWRImmutable(
    ['getUsageSubCategoriesByMain', 'AI_CLIP'],
    () => getUsageSubCategoriesByMain('AI_CLIP'),
    {
      onSuccess: (data) => {
        refreshThisMonthCreditUsage(data);
      },
    },
  );

  const refreshThisMonthCreditUsage = useCallback(
    (usageSubCategoriesParam?: TUsageSubCategoriesByMain) => {
      const finalUsageSubCategories = usageSubCategoriesParam ?? usageSubCategoriesByMain;

      if (isUndefined(finalUsageSubCategories)) return 0;

      const { subCategories } = finalUsageSubCategories;
      const { year, month } = getThisMonth();

      const requests = subCategories.map(({ subCategory }) => getCreditUsage('AI_CLIP', subCategory, year, month));

      // 모든 sub category의 이번 달 사용량 총합
      Promise.all(requests).then((creditUsageList) => {
        const sum = creditUsageList.reduce((acc, { creditUsages }) => acc + sumMonthCreditUsage(creditUsages), 0);
        setThisMonthCreditUsageSeconds(sum);
      });
    },
    [usageSubCategoriesByMain],
  );

  return {
    thisMonthCreditUsageSeconds,
    refreshThisMonthCreditUsage,
  };
}
