import { AiClipDownloadMultiProgressPopup } from '@components/ai-clip/AiClipDownloadMultiProgressPopup';
import { MultiUpload } from '@components/multiUpload/MultiUpload';
import { ReactElement, useMemo } from 'react';
import styled, { RuleSet, css } from 'styled-components';
import { useVideoStore } from '@store/videoStore';
import { useMultipProgressPopupStore } from '@store/multiProgressPopupStore/multiProgressPopupStore';
import { isEmpty } from 'lodash';

type PopupPosition = 'LEFT_BOTTOM' | 'HIDDEN';

/**
 * 업로드, 다운로드 등의 작업을 독립적으로 수행하는 팝업 모음.
 * 1. 미디어 업로드 팝업
 * 2. AI Clip Section 아카이브 다운로드 팝업
 *
 * Note:
 * 멀티 프로그레스 팝업은 항상 좌측 하단에 위치하도록 결정.
 *  - 이유1: AI Clip > 채널톡 버튼이 우측 하단에 위치하기 때문에 겹침.
 *  - 이유2: 전체 모달이 열리는 경우 CTA 버튼이 주로 우측 하단에 있어서 팝업과 겹침.
 */
export const MultiProgressPopupGroup = (): ReactElement => {
  const [aiCilpDownloads] = useMultipProgressPopupStore((state) => [state.aiCilpDownloads]);
  const [videoListModalState] = useVideoStore((state) => [state.videoListModalState]);

  const popupPosition: PopupPosition = useMemo(
    () => (videoListModalState === 'EDIT_MODE' || videoListModalState === 'HIDDEN' ? 'HIDDEN' : 'LEFT_BOTTOM'),
    [videoListModalState],
  );

  return (
    <StyledPositioner $popupPosition={popupPosition}>
      <MultiUpload />
      {!isEmpty(aiCilpDownloads) && <AiClipDownloadMultiProgressPopup />}
    </StyledPositioner>
  );
};

const positionerCssMap: Record<PopupPosition, RuleSet<object>> = {
  LEFT_BOTTOM: css`
    bottom: 16px;
    left: 16px;
  `,
  HIDDEN: css`
    display: none;
  `,
};

const StyledPositioner = styled.div<{ $popupPosition: PopupPosition }>`
  display: flex;
  align-items: flex-end;
  gap: 16px;
  position: fixed;
  z-index: 1060; // The z-index of <CustomTooltip> is 1070. Therefore, it is 1060.
  ${({ $popupPosition }) => positionerCssMap[$popupPosition]}
`;
