import { t } from '@common/helper/languages';
import { doesPathMatchPatterns } from '@common/utils/path-matcher';
import { useRouter } from '@hooks/useRouter';
import { CSSProperties, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { SidebarBadge, SidebarBadgeType } from './SidebarBadge';
import { StyledSideMenuItem } from './side-menu-styled';

interface ISideMenuItemProps {
  icon?: ReactNode;
  title: string;
  path: string;
  relatedPath?: string[];
  forceSelected?: boolean;
  customStyle?: CSSProperties;
  italic?: boolean;
  query?: string;
  badge?: SidebarBadgeType;
  children?: ReactNode;
}

const SideMenuItem: React.FC<ISideMenuItemProps> = ({
  icon,
  title,
  path,
  relatedPath,
  forceSelected,
  customStyle,
  children,
  italic,
  query,
  badge,
}) => {
  const location = useLocation();
  const router = useRouter();

  const isSelectedPath = relatedPath ? doesPathMatchPatterns(location.pathname, relatedPath || []) : false;
  const selected = forceSelected || path === location.pathname || isSelectedPath;

  const onClickItem = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    if (location.pathname !== path || query) {
      if (query) {
        const target = path + (query.startsWith('?') ? '' : '?') + query;
        router.push(target);
      } else {
        router.push(path);
      }
    }
  };

  return (
    <StyledSideMenuItem
      selected={selected}
      onClick={onClickItem}
      style={customStyle}
      className={italic ? 'italic' : ''}
    >
      {icon}
      <div className="wrap-title">{t(title)}</div>
      {badge && <SidebarBadge style={{ marginLeft: 8 }} badge={badge} />}
      <div
        className="wrap-children"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        {children}
      </div>
    </StyledSideMenuItem>
  );
};

export default SideMenuItem;
