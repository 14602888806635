import { message } from 'antd';
import { ReactComponent as SuccessIcon } from '@assets/svg/Fill__Systems--checkbox-circle-fill.svg';
import { ReactComponent as FailIcon } from '@assets/svg/Fill__Systems--error-warning-fill.svg';

import { ArgsProps } from 'antd/lib/message';

export const useMessage = () => {
  const customMessage = (
    type: 'success' | 'error' = 'success',
    messageProps:
      | ArgsProps
      | {
          content: React.ReactNode;
        },
  ) => {
    const options = {
      className: 'ant-custom-success-message',
      icon: type === 'success' ? <SuccessIcon /> : <FailIcon />,
      ...messageProps,
    };

    if (type === 'success') {
      message.success(options);
    } else {
      message.error(options);
    }
  };

  return { customMessage };
};
