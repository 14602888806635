import {
  getCustomerId,
  getToken,
  getLoginPath,
  saveToken,
  setSessionStorageCustomerId,
  removeToken,
} from '@common/helper/auth';
import { languageManager } from '@common/helper/languages';
import RegionManager from '@common/regions';
import * as qs from 'querystring';

Error.prototype.statusCode = null;

const revalidateAuthorization = (r: Response) => {
  const authNew = r.headers.get('Authorization');
  if (authNew) {
    saveToken(authNew, null, null);
  }
};

const refreshCustomerId = (res: Response) => {
  // 넘어온 커스터머 id 가 있으면, customerId 를 세션스토리지에 저장해둔다.
  const customerId = res.headers.get('x-sl-customer-id') || res.headers.get('customer-id');
  if (customerId) {
    setSessionStorageCustomerId(customerId);
  }
};

const defaultFetch = (url: string, method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' = 'GET', data = {}) => {
  // customerId 가 존재하면, 모든 요청 query 에 _ci 를 붙인다.
  const customerId = getCustomerId();

  if (customerId && url) {
    const urlObj = new URL(url);
    urlObj.searchParams.set('_ci', customerId);
    url = urlObj.href;
  }

  const request: RequestInit = {
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'omit', // include, *same-origin, omit
    method,
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  };
  if (url.startsWith(RegionManager.apiEndpoint) || url.startsWith(RegionManager.apiEndpoints?.papiEndpoint)) {
    request.headers = { ...request.headers, ...getToken() };
  } else {
    console.log('No authorization header, because of different request domain : ', url);
  }

  if (method !== 'GET') {
    request.body = JSON.stringify(data);
  }

  const f = fetch(url, request);

  return new Promise<any>((resolve, reject) => {
    f.then((response) => {
      const r = response.clone();
      //401 Unauthorized 일경우에는, login 페이지로 그냥 이동시켜줘 버리자.
      if (r.status === 401 && !location.hash.includes('login')) {
        // getMe() 에서 나는 401 은 exception 을 던져준다.
        if (url.includes('administrator/me')) {
          removeToken();
          const e = new Error('Unauthorized');
          e.statusCode = 401;
          reject(e);
          return;
        }

        const whiteArr = ['api/customers/children', 'api/administrator/campaign/', 'api/cafe24/'];
        const isWhiteUrl = whiteArr.find((val) => url.includes(val));

        if (!isWhiteUrl) {
          window.location.href = '/#' + getLoginPath(true);
          //Fragment 이동으로 페이지가 새로고침 되지않았을 때를 대비해서 location.reload. 새로고침이 된다면, 아래 함수는 무시되므로 냅두기.
          setTimeout(() => {
            location.reload();
          }, 10);
          return;
        }
      }

      revalidateAuthorization(r);
      refreshCustomerId(r);

      //일단 json 으로 파싱한다.
      r.json()
        .then((json) => {
          if (!r.ok) {
            // NOTE 기존에는 서버에 요청을 했을 때 에러가 발생 하더라도 HTTP 상태 코드 200 OK를 내려주고 있었음(서버와의 통신에 성공했다는 의미로서)
            //      그러나 앞으로는 에러가 발생하였을 때 정확한 HTTP 상태 코드 4XX, 5XX를 내려주도록 변경됨.
            const e = new Error(`${json._e ?? json._s ?? json.message ?? json.error ?? ''}`);
            e.statusCode = r.status;
            reject(e);
          }

          //만약에 _s가 없거나, 0이라면 성공이다.
          if (!json._s || json._s == 0) {
            resolve(json);
          } else {
            //_s가 0이 아니라면, reject 해주자.
            const e = new Error(json?._e ?? json?._s);
            e.statusCode = r.status;
            reject(e);
          }
        })
        .catch((error) => {
          if (r.ok) {
            // 아주가끔 text 형식이 올 때 있다. 만약 text 형식이라면, text 로 resolve 해준다.
            if (r.headers.get('Content-Type')?.startsWith('text/plain')) {
              response.text().then((text) => {
                resolve(text);
              });
            } else {
              //json 파싱 실패했는데, 서버 응답이 2xx 라면, 빈 오브젝트를 then으로 넘겨준다. (No-Content, Created 등등...)
              resolve({});
            }
          } else {
            if (r.status === 502) {
              reject(error);
            }
            //파싱도 실패하고, 2xx도 아니라면 error 로 넘겨줌.
            else if (typeof r === 'object') {
              const e = new Error(`${r.status} ${r.statusText} ${error}`);
              e.statusCode = r.status;
              reject(e);
            } else {
              reject(error);
            }
          }
        });
    }).catch((error) => {
      reject(error);
    });
  });

  // return f.then((r: any) => {
  //   if (!r.ok || r.status >= 400) {
  //     try {
  //       return r.clone().json();
  //     } catch (error) {
  //       throw new Error(error);
  //     }
  //   }

  //   // No-Content일 경우
  //   if (r.status === 204) {
  //     return {};
  //   }
  //   return r
  //     .json()
  //     .then((json: any) => json)
  //     .catch(() => null);
  // });
};

export const fetchPlainTextContentType = async (url = '', method = 'POST', body = '', config = {}) => {
  const opt: RequestInit = {
    method,
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'omit',
    headers: {
      ...getToken(),
      'Content-Type': 'text/plain;charset=UTF-8',
      ...config,
    },
    body,
  };
  if (languageManager.currentLanguage) {
    (opt.headers as any)['x-sl-language'] = languageManager.currentLanguage;
  }
  try {
    const response = await fetch(url, opt);
    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }
    const data = await response.text();
    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const fetchFormData = (url = '', method = 'GET', data: any) => {
  // customerId 가 존재하면, 모든 요청 query 에 _ci 를 붙인다.
  const customerId = getCustomerId();
  if (customerId && url) {
    const urlObj = new URL(url);
    urlObj.searchParams.set('_ci', customerId);
    url = urlObj.href;
  }

  const opt: RequestInit = { mode: 'cors', cache: 'no-cache', method: method, headers: {} };

  if (method === 'GET') {
    opt.credentials = 'include';
  } else {
    opt.credentials = 'omit';
    opt.headers = { ...getToken() };
    opt.body = data;
  }

  if (languageManager.currentLanguage) {
    (opt.headers as any)['x-sl-language'] = languageManager.currentLanguage;
  }

  return fetch(url, opt)
    .then((res) => {
      revalidateAuthorization(res);
      refreshCustomerId(res);
      if (!res.ok || res.status >= 400) {
        return res
          .clone()
          .json()
          .then((rData) => {
            const errorMessage = rData._e || rData.message || res.statusText;
            throw new Error(errorMessage);
          });
      }
      try {
        return res.json();
      } catch (error) {
        return {};
      }
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export const defaultFetchAsync = async (url: string, method: 'GET' | 'POST' | 'PUT' | 'DELETE' = 'GET', data = {}) => {
  try {
    const ret = await defaultFetch(url, method, data);
    return {
      data: ret,
    };
  } catch (error) {
    return {
      error,
    };
  }
};

export const postFormDataForLogin = async (url = '', method = 'POST', data = {}, config = {}) => {
  const opt: RequestInit = {
    mode: 'cors',
    method,
    cache: 'no-cache',
    credentials: 'omit',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      ...config,
    },
    body: qs.stringify(data),
  };

  if (languageManager.currentLanguage) {
    (opt.headers as any)['x-sl-language'] = languageManager.currentLanguage;
  }

  const response = await fetch(url, opt)
    .then((res) => {
      if (res.ok) {
        revalidateAuthorization(res);
        refreshCustomerId(res);
      }
      // API 에서 200 이 안떨어지고 4xx 로 받는것으로 변경되었다.  2xx 와 무관하게 json 으로 보내준다. _e 와 _s 를 사용해야 하므로.
      return res.json();
    })
    .catch((err) => {
      console.error(err);
    });
  return response;
};

export const postFormDataForOAuth = async (path: string, query: any) => {
  const url = `${path}?${qs.encode(query)}`;
  const response = await fetch(url, { mode: 'cors', method: 'POST', cache: 'no-cache', credentials: 'omit' })
    .then((res) => {
      if (res.ok) {
        revalidateAuthorization(res);
        refreshCustomerId(res);
        return res.json();
      }
      return res;
    })
    .catch((err) => {
      console.error(err);
    });
  return response;
};

export default defaultFetch;
