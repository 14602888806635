import 'antd/dist/antd.less';
import { Button } from 'antd';
import Highlight from 'react-highlight';
import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  .italic {
    font-style: italic;
  }  

  .weight-500 {
    font-weight: 500;
  }

  @font-face {
    font-family: "Pretendard";
    src: url("https://static.shoplive.cloud/fonts/Pretendard-Black.woff") format("woff");
    font-weight: 900;
  }

  @font-face {
    font-family: "Pretendard";
    src: url("https://static.shoplive.cloud/fonts/Pretendard-ExtraBold.woff") format("woff");
    font-weight: 800;
  }

  @font-face {
    font-family: "Pretendard";
    src: url("https://static.shoplive.cloud/fonts/Pretendard-Bold.woff") format("woff");
    font-weight: 700;
  }

  @font-face {
    font-family: "Pretendard";
    src: url("https://static.shoplive.cloud/fonts/Pretendard-SemiBold.woff") format("woff");
    font-weight: 600;
  }

  @font-face {
    font-family: "Pretendard";
    src: url("https://static.shoplive.cloud/fonts/Pretendard-Medium.woff") format("woff");
    font-weight: 500;
  }

  @font-face {
    font-family: "Pretendard";
    src: url("https://static.shoplive.cloud/fonts/Pretendard-Regular.woff") format("woff");
    font-weight: 400;
  }

  @font-face {
    font-family: "Pretendard";
    src: url("https://static.shoplive.cloud/fonts/Pretendard-Light.woff") format("woff");
    font-weight: 300;
  }

  @font-face {
    font-family: "Pretendard";
    src: url("https://static.shoplive.cloud/fonts/Pretendard-ExtraLight.woff") format("woff");
    font-weight: 200;
  }

  @font-face {
    font-family: "Pretendard";
    src: url("https://static.shoplive.cloud/fonts/Pretendard-Thin.woff") format("woff");
    font-weight: 100;
  }

  




  @font-face {
    font-family: 'Helvetica Neue';
    src: local('Helvetica Neue'),
    url('https://static.shoplive.cloud/fonts/HelveticaNeue-Regular.woff') format('woff');
    font-weight: 400;
    unicode-range: U+0020-002f, U+003A-0040, U+005B-0060, U+007B-007E;
  }

  @font-face {
    font-family: 'Helvetica Neue';
    src: local('Helvetica Neue'),
    url('https://static.shoplive.cloud/fonts/HelveticaNeue-Light.woff') format('woff');
    font-weight: 300;
    unicode-range: U+0020-002f, U+003A-0040, U+005B-0060, U+007B-007E;
  }

  @font-face {
    font-family: 'Helvetica Neue';
    src: local('Helvetica Neue'),
    url('https://static.shoplive.cloud/fonts/HelveticaNeue-Medium.woff') format('woff');
    font-weight: 500;
    unicode-range: U+0020-002f, U+003A-0040, U+005B-0060, U+007B-007E;
  }

  @font-face {
    font-family: 'Helvetica Neue';
    src: local('Helvetica Neue'),
    url('https://static.shoplive.cloud/fonts/HelveticaNeue-Bold.woff') format('woff');
    font-weight: bold;
    unicode-range: U+0020-002f, U+003A-0040, U+005B-0060, U+007B-007E;
  }
   





  // Noto Sans KR
@font-face {
  font-family: Noto Sans KR;
  src: url("https://static.shoplive.cloud/fonts/NotoSansKR-Regular.woff2") format("woff2"),
    url("https://static.shoplive.cloud/fonts/NotoSansKR-Regular.woff") format("woff");
  font-weight: 400;
  unicode-range: U+1100-11FF, U+3130-318F, U+A960-A97F, U+AC00-D7A3, U+D7B0-D7FF;
}
@font-face {
  font-family: Noto Sans KR;
  src: url("https://static.shoplive.cloud/fonts/NotoSansKR-Bold.woff2") format("woff2"),
    url("https://static.shoplive.cloud/fonts/NotoSansKR-Bold.woff") format("woff");
  font-weight: bold;
  unicode-range: U+1100-11FF, U+3130-318F, U+A960-A97F, U+AC00-D7A3, U+D7B0-D7FF;
}
@font-face {
  font-family: Noto Sans KR;
  src: url("https://static.shoplive.cloud/fonts/NotoSansKR-Light.woff2") format("woff2"),
    url("https://static.shoplive.cloud/fonts/NotoSansKR-Light.woff") format("woff");
  font-weight: 300;
  unicode-range: U+1100-11FF, U+3130-318F, U+A960-A97F, U+AC00-D7A3, U+D7B0-D7FF;
}
@font-face {
  font-family: Noto Sans KR;
  src: url("https://static.shoplive.cloud/fonts/NotoSansKR-Medium.woff2") format("woff2"),
    url("https://static.shoplive.cloud/fonts/NotoSansKR-Medium.woff") format("woff");
  font-weight: 500;
  unicode-range: U+1100-11FF, U+3130-318F, U+A960-A97F, U+AC00-D7A3, U+D7B0-D7FF;
}
@font-face {
  font-family: Noto Sans KR;
  src: url("https://static.shoplive.cloud/fonts/NotoSansKR-Thin.woff2") format("woff2"),
    url("https://static.shoplive.cloud/fonts/NotoSansKR-Thin.woff") format("woff");
  font-weight: 200;
  unicode-range: U+1100-11FF, U+3130-318F, U+A960-A97F, U+AC00-D7A3, U+D7B0-D7FF;
}

// Roobert
@font-face {
  font-family: Roobert;
  src: url("https://static.shoplive.cloud/fonts/Roobert-Regular.woff") format("woff");
  font-weight: 400;
}
@font-face {
  font-family: Roobert;
  src: url("https://static.shoplive.cloud/fonts/Roobert-Medium.woff") format("woff");
  font-weight: 500;
}

@font-face {
  font-family: Roobert;
  src: url("https://static.shoplive.cloud/fonts/Roobert-SemiBold.woff") format("woff");
  font-weight: 600;
}
@font-face {
  font-family: Roobert;
  src: url("https://static.shoplive.cloud/fonts/Roobert-Bold.woff") format("woff");
  font-weight: bold;
}





  body {
    font-family: "Pretendard", "Noto Sans KR", 'Helvetica Neue', "Roobert", -apple-system, BlinkMacSystemFont, 
    "Segoe UI", 
    Roboto, Oxygen-Sans, 
    Ubuntu,
    Cantarell, "Helvetica Neue", sans-serif;
    font-feature-settings: 'liga' 0, 'dlig' 0;
    font-variant: normal;
  }

  #root {
    height: 100%;
  }

  .custom-align-center{
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-active-dropdown{
    .ant-dropdown-menu-item-active {
    background-color: unset;
  }
}

.child-dropdown{
  top:60px !important;
  .ant-dropdown-menu{
width: 150px;
  }
}

  .row-dragging {
    &.campaign-goods-group {
      // 체크박스
      .ant-table-selection-column {
        width: 55px;
        text-align: center;
      }
      .image {
        width: 64px;
        text-align: center;
      }
      .name {
        width: 200px;
      }
      .brand {
      }
      .sort {

      }
    }
    td {
      padding: 12px 8px;
    }
  }

  .row-item.row-selected {
    td {
      background: #fff1f1;
    }
  }


  .c-multi-drag-table.is-dragging table {
  border: 1px solid #4285f4;
}
.c-multi-drag-table .ant-col {
  position: relative;
}
.c-multi-drag-table .ant-col:first-child:after {
  display: block;
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 1px;
  height: 100%;
  background: #e2e2e2;
  z-index: 1;
}
.c-multi-drag-table .inner-col {
  position: relative;
  height: 100%;
  background: #fff;
  border: 1px solid #f0f0f0;
  padding: 10px;
  /* z-index: 2; */
}
.c-multi-drag-table .ant-table {
  border: 1px solid #f0f0f0;
}
.c-multi-drag-table .ant-table-thead > tr > th,
.c-multi-drag-table .ant-table-tbody > tr > td,
.c-multi-drag-table .ant-table tfoot > tr > th,
.c-multi-drag-table .ant-table tfoot > tr > td {
  padding: 6px 16px;
}
.c-multi-drag-table .ant-table-tbody > tr.ant-table-placeholder > td,
.c-multi-drag-table .ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background: transparent;
}
.c-multi-drag-table .ant-table-tbody > tr.row-item {
  background: #fff;
}
.c-multi-drag-table
  .ant-table-tbody.is-dragging-over
  > tr.ant-table-placeholder {
  background: #deebff !important;
}
.c-multi-drag-table .ant-table-tbody > tr.row-item.row-selected {
  background: #deebff;
}
.c-multi-drag-table .ant-table-tbody > tr.row-item.row-dragging {
  display: table;
  color: #fff;
  background: #4285f4;
  border: 1px solid #f0f0f0;
}
.c-multi-drag-table .ant-table-tbody > tr.row-item.row-dragging > td {
  border-bottom: 0;
}
.c-multi-drag-table .ant-table-tbody > tr.row-item.row-ghosting {
  opacity: 0.5;
}
`;

export const ScrollbarWrapper = styled.div`
  .fl-scrolls {
    bottom: 0;
    height: 35px;
    overflow: auto;
    position: fixed;
  }
  .fl-scrolls,
  .fl-scrolls div {
    font-size: 0;
    line-height: 0;
    margin: 0;
    padding: 0;
  }
  .fl-scrolls div {
    height: 1px;
    overflow: hidden;
  }
  .fl-scrolls div:before {
    content: '';
  }
  .fl-scrolls-hidden {
    bottom: 9999px;
  }
  .fl-scrolls-viewport {
    position: relative;
  }
  .fl-scrolls-body {
    overflow: auto;
  }
  .fl-scrolls-viewport .fl-scrolls {
    left: 0;
    position: absolute;
  }
  .fl-scrolls-hoverable .fl-scrolls {
    opacity: 0;
    transition: opacity 0.5s ease 0.3s;
  }
  .fl-scrolls-hoverable:hover .fl-scrolls {
    opacity: 1;
  }
`;

type ButtonSizeType = 'small' | 'large';
type ButtonColorType = 'black' | 'red' | 'orange';

export const ActionButton = styled(Button)<{ color?: ButtonColorType; size?: ButtonSizeType }>`
  border-radius: 4px;

  &.hover {
    &:hover {
      background: #f6f6f6;
    }
  }

  &.ant-dropdown-open {
    background: #f6f6f6;
  }

  ${(props) => `
  ${
    props.size && props.size === 'small'
      ? `
      height: 28px;
      font-size: 12px;
      line-height: 10px;
      padding-left: 10px;
      padding-right: 10px;

      &.ant-btn-icon-only {
        width: 28px;
        height: 28px;
      }
      `
      : props.size === 'large'
      ? `
      height: 44px;
      font-size: 14px;
      line-height: 10px;
      padding-left: 22px;
      padding-right: 22px;
      `
      : `
      height: 36px;
      font-size: 13px;
      line-height: 10px;
      padding-left: 16px;
      padding-right: 16px;
     `
  }
    `}
  &:hover {
    ${(props) =>
      props.color === 'orange' &&
      `
      color: #ED6A20;
      border: 1px solid #ED6A20;
    `}

    ${(props) =>
      props.color === 'red' &&
      `
      color: #EF3434;
      border: 1px solid #EF3434;
    `}
  }
`;

export const HighlightText = styled(Highlight)`
  font-size: 12px;
  color: #fff;
  background: #282c34;
  display: block;
  overflow-x: auto;
  padding: 1em;
  .hljs {
  }
  .hljs-comment,
  .hljs-quote {
    color: #5c6370;
    font-style: italic;
  }
  .hljs-doctag,
  .hljs-formula,
  .hljs-keyword {
    color: #c678dd;
  }
  .hljs-params {
    // color: #fff;
  }
  .hljs-deletion,
  .hljs-name,
  .hljs-section,
  .hljs-selector-tag,
  .hljs-subst {
    color: #e06c75;
  }
  .hljs-literal {
    color: #56b6c2;
  }
  .hljs-addition,
  .hljs-attribute,
  .hljs-meta-string,
  .hljs-regexp,
  .hljs-string {
    color: #98c379;
  }
  .hljs-built_in,
  .hljs-class .hljs-title {
    color: #e6c07b;
  }
  .hljs-attr,
  .hljs-number,
  .hljs-selector-attr,
  .hljs-selector-class,
  .hljs-selector-pseudo,
  .hljs-template-variable,
  .hljs-type,
  .hljs-variable {
    color: #d19a66;
  }
  .hljs-bullet,
  .hljs-link,
  .hljs-meta,
  .hljs-selector-id,
  .hljs-symbol,
  .hljs-title {
    color: #61aeee;
  }
  .hljs-emphasis {
    font-style: italic;
  }
  .hljs-strong {
    font-weight: bold;
  }
  .hljs-link {
    text-decoration: underline;
  }
`;
