import { deviceSize } from '@styles/theme';
import React, { useContext, useEffect, useRef, useState } from 'react';

type BeforeInit = {
  hasInitialized: false;
  isLaptopSmall: false;
  isDesktop: false;
};
type AfterInit = {
  hasInitialized: true;
  isLaptopSmall: boolean;
  isDesktop: boolean;
};

type MediaQueryContextValue = BeforeInit | AfterInit;

const MediaQueryContext = React.createContext<MediaQueryContextValue | undefined>(undefined);

const beforeInitial: BeforeInit = {
  hasInitialized: false,
  isLaptopSmall: false,
  isDesktop: false,
};

export const MediaQueryProvider = ({ children }: { children: React.ReactNode }) => {
  const [value, setValue] = useState<MediaQueryContextValue>(beforeInitial);
  const hasInitializedRef = useRef<boolean>(value.hasInitialized);

  const handleLaptopSmallChange = (event: MediaQueryListEvent) => {
    setValue({
      hasInitialized: true,
      isLaptopSmall: event.matches,
      isDesktop: false,
    });
  };
  const handleDesktopChange = (event: MediaQueryListEvent) => {
    setValue({
      hasInitialized: true,
      isLaptopSmall: false,
      isDesktop: event.matches,
    });
  };

  useEffect(() => {
    const laptopSmall = parseInt(deviceSize.laptop_small);
    const desktop = parseInt(deviceSize.desktop);

    const laptopSmallMq = window.matchMedia(`(max-width: ${laptopSmall}px)`);
    const desktopMq = window.matchMedia(`(min-width: ${desktop}px)`);

    if (!hasInitializedRef.current) {
      setValue({
        hasInitialized: true,
        isLaptopSmall: laptopSmallMq.matches,
        isDesktop: desktopMq.matches,
      });

      hasInitializedRef.current = true;
    }

    laptopSmallMq.addEventListener('change', handleLaptopSmallChange);
    desktopMq.addEventListener('change', handleDesktopChange);

    return () => {
      laptopSmallMq.removeEventListener('change', handleLaptopSmallChange);
      desktopMq.removeEventListener('change', handleDesktopChange);
    };
  }, []);

  return <MediaQueryContext.Provider value={value}>{children}</MediaQueryContext.Provider>;
};

export const useMediaQuery = (): MediaQueryContextValue => {
  const mediaQueryValue = useContext<MediaQueryContextValue | undefined>(MediaQueryContext);

  if (!mediaQueryValue) {
    throw new Error(
      'useMediaQuery hook must be used within a MediaQueryProvider.\nDid you wrap your component with MediaQueryProvider?',
    );
  }

  return mediaQueryValue;
};
