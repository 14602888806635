import RegionManager from '@common/regions';
import { Select } from 'antd';
import { ReactComponent as ArrowDownIcon } from '@assets/svg/arrow-down-s-line.svg';
// 리전 선택하는 셀렉트.

const RegionSelector: React.FC = () => {
  if (RegionManager.availableRegions.length <= 1) {
    return null;
  }

  return (
    <Select
      className="region-selector"
      value={RegionManager.apiEndpoint}
      onChange={(val) => {
        RegionManager.saveApiEndpoint(val);
        location.href = '#/';
      }}
      suffixIcon={<ArrowDownIcon />}
    >
      {RegionManager.availableRegions.map((region) => (
        <Select.Option key={region.adminApiUrl} value={region.adminApiUrl}>
          {region.title}
        </Select.Option>
      ))}
    </Select>
  );
};

export default RegionSelector;
