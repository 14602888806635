import { createContext, useContext, useReducer, useMemo, useCallback } from 'react';
import { AuthorityReducer, TAuthorityInitialValues, initialState } from './reducer';

import { actions } from './action';
import { TMainAdministrator } from 'types/administrator';

interface IAuthorityContext extends TAuthorityInitialValues {}

interface IAuthorityDispatch {
  setLanguage: (lang: string) => void;
  setAdministrator: (admin: TMainAdministrator | null) => void;
}

const AuthorityContext = createContext<IAuthorityContext | undefined>(undefined);
const AuthorityDispatchContext = createContext<React.Dispatch<any> | undefined>(undefined);

export const useAuthorityState = (): IAuthorityContext => {
  const context = useContext(AuthorityContext);
  if (context === undefined) {
    throw new Error('useAuthorityState can only be used within AuthorityProvider.');
  }
  return context;
};

export const useAuthorityDispatch = (): IAuthorityDispatch => {
  const dispatch = useContext(AuthorityDispatchContext);
  if (dispatch === undefined) {
    throw new Error('useAuthorityDispatch can only be used within AuthorityProvider.');
  }
  const setLanguage = useCallback((lang: string) => dispatch(actions.setLanguage(lang)), [dispatch]);
  const setAdministrator = useCallback(
    (admin: TMainAdministrator | null) => dispatch(actions.setAdministrator(admin)),
    [dispatch],
  );

  return { setLanguage, setAdministrator };
};

const AuthorityProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(AuthorityReducer, initialState);
  const memoizedState = useMemo(() => state, [state]);

  return (
    <AuthorityContext.Provider value={{ ...memoizedState }}>
      <AuthorityDispatchContext.Provider value={dispatch}>{children}</AuthorityDispatchContext.Provider>
    </AuthorityContext.Provider>
  );
};

export default AuthorityProvider;
