import { GlobalStyle } from '@styles/globals';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from 'styled-components';
import { SWRConfig } from 'swr';
import fetch from '@common/libs/fetch';
import theme from '@styles/theme';
import 'antd/dist/antd.min.css';
import '@assets/antd-custom.less';
import App from './App';
import { MediaQueryProvider } from '@hooks/useMediaQuery';

// eslint-disable-next-line import/no-named-as-default-member
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <>
    <GlobalStyle />
    <ThemeProvider theme={theme}>
      <MediaQueryProvider>
        <SWRConfig
          value={{
            fetcher: fetch,
            revalidateOnFocus: false,
          }}
        >
          <App />
        </SWRConfig>
      </MediaQueryProvider>
    </ThemeProvider>
  </>,
);
