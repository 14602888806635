import defaultFetch, { fetchFormData, postFormDataForLogin } from '@common/libs/fetch';
import RegionManager, { IAdminApiEndpoint } from '@common/regions';
import useSWR from 'swr';
import { TProfileSetting } from 'types/settings';
import { TListResponse } from '@localTypes/response';
import { TAdminAuthority, TBriefAdministrator } from '@localTypes/administrator';
export const me = {};

const ADMINISTRATOR = '/administrator';
const PROFILE = '/profile';

export const getAllEndpoints = () => {
  const url = `${RegionManager.apiEndpoint}/system/endpoints`;
  return useSWR<IAdminApiEndpoint[]>(url);
};

export const getLanguageResource = (lang: string) => {
  const url = `${RegionManager.apiEndpoint}/internal/resource/admin/${lang}`;
  return defaultFetch(url);
};

export const getAdministratorRoles = (type: string) => {
  const url = `${RegionManager.apiEndpoint}/administrator/roles?type=${type}`;
  return defaultFetch(url, 'GET');
};

export const getHostWithServerLocation = (serverLocation: string | string[] | undefined) => {
  switch (serverLocation) {
    case 'us-east-2':
      return 'https://admin.us1.shoplive.cloud/api';
    default:
      return RegionManager.apiEndpoint;
  }
};

export const validateSessionSecret = (
  sessionSecret: string,
  serverLocation: string | string[] | undefined,
): Promise<any> => {
  const host = getHostWithServerLocation(serverLocation);
  const url = `${host}/administrator/session-secret/validate?sessionSecret=${sessionSecret}`;
  return defaultFetch(url, 'GET');
};

export const setupCustomerAdministrator = async (
  data: {
    sessionSecret: string;
    password: string;
    language: string;
    name: string;
  },
  serverLocation: string | string[] | undefined,
): Promise<any> => {
  const host = getHostWithServerLocation(serverLocation);
  const url = `${host}/administrator/customer/register/setup`;
  return await postFormDataForLogin(url, 'POST', data);
};

export const renewPassword = async (
  data: { sessionSecret: string; password: string },
  serverLocation: string | string[] | undefined,
): Promise<any> => {
  const host = getHostWithServerLocation(serverLocation);
  const url = `${host}/administrator/password/renew`;
  return await postFormDataForLogin(url, 'PUT', data);
};

export const updateProfile = async (data: TProfileSetting, timezone: string | null = null) => {
  let url = `${RegionManager.apiEndpoint + ADMINISTRATOR + PROFILE}`;
  if (timezone) {
    url += `?timezone=${timezone}`;
  }
  return defaultFetch(url, 'PUT', data);
};

export const resetAdministratorPassword = (id: string, withAuth: boolean = true) => {
  const url = RegionManager.apiEndpoint + '/administrator/password/' + (withAuth ? 'resetWithAuth' : 'reset');
  const formData = new FormData();
  formData.append('id', id);
  return fetchFormData(url, 'PUT', formData);
};

export const resetAdministratorPasswordIncorrectCount = (adminId: string) => {
  const url = RegionManager.apiEndpoint + '/administrator/password/resetPasswordIncorrectCount';
  const formData = new FormData();
  formData.append('adminId', adminId);
  return fetchFormData(url, 'PUT', formData);
};

export const getCustomerAdministrators = (customerId?: string) => {
  if (customerId === 'new') {
    return useSWR<TListResponse<TBriefAdministrator>>(null);
  }
  const url = customerId ? `${RegionManager.apiEndpoint}/administrator/customer/${customerId}` : null;
  return useSWR<TListResponse<TBriefAdministrator>>(url);
};

export const getCustomerAdminAccount = (customerId?: string, adminId?: string) => {
  return defaultFetch(`${RegionManager.apiEndpoint}/administrator/customer/${customerId}/${adminId}`);
};

export const createCustomerAdministrator = (customerId: string, data: any) => {
  const url = RegionManager.apiEndpoint + `/administrator/customer?customerId=${customerId}`;
  return defaultFetch(url, 'POST', data);
};

export const updateCustomerAdministrator = (customerId: string, data: any) => {
  const url = RegionManager.apiEndpoint + `/administrator/customer?customerId=${customerId}`;
  return defaultFetch(url, 'PUT', data);
};

export const deleteCustomerAdministrator = (customerId: string, adminId: string) => {
  const url = `${RegionManager.apiEndpoint}/administrator/customer?adminId=${adminId}&customerId=${customerId}`;
  return defaultFetch(url, 'DELETE');
};

// 캠페인 단위 관리자 계정.
export const getCampaignAdministrators = (campaignId?: string) => {
  if (!campaignId) {
    return useSWR<TListResponse<TBriefAdministrator>>(null);
  }
  const url = `${RegionManager.apiEndpoint}/administrator/campaign/${campaignId}`;
  return useSWR<TListResponse<TBriefAdministrator>>(url);
};

export const createCampaignAdministrator = (campaignId: string, data: any) => {
  const url = `${RegionManager.apiEndpoint}/administrator/campaign/${campaignId}`;
  return defaultFetch(url, 'POST', data);
};

export const updateCampaignAdministrator = (campaignId: string, data: any) => {
  const url = `${RegionManager.apiEndpoint}/administrator/campaign/${campaignId}`;
  return defaultFetch(url, 'PUT', data);
};

export const deleteCampaignAdministrator = (campaignId: string, adminId: string) => {
  const url = `${RegionManager.apiEndpoint}/administrator/campaign/${campaignId}?adminId=${adminId}`;
  return defaultFetch(url, 'DELETE');
};

export const getCampaignAuthority = (campaignId?: string) => {
  if (!campaignId) {
    return useSWR<TAdminAuthority>(null);
  }

  const url = `${RegionManager.apiEndpoint}/administrator/authority/${campaignId}`;
  return useSWR<TAdminAuthority>(url);
};

export const getOtpCodeVerifyUrl = () => {
  let url = `${RegionManager.apiEndpoint}/administrator/login/auth0`;
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (timezone) {
    url += `?timezone=${encodeURIComponent(timezone)}`;
  }
  return url;
};

export const updateAdministratorUseOtp = (email: string, use: boolean) => {
  const url = `${RegionManager.apiEndpoint}/administrator/otp/active?ids=${email}&useOtp=${use}`;
  return defaultFetch(url, 'PATCH');
};

export const resetAdministratorOtp = (email: string) => {
  const url = `${RegionManager.apiEndpoint}/administrator/otp/reset?ids=${email}`;
  return defaultFetch(url, 'PATCH');
};

export const getCampaignVersionStatus = (key: string, value: string) => {
  const url = `${RegionManager.apiEndpoint}/administrator/tag?key=${key}&value=${value}`;
  return defaultFetch(url, 'PUT');
};

export const insertResourcesImport = () => {
  const url = `${RegionManager.apiEndpoint}/resources/import`;
  return defaultFetch(url, 'POST');
};

export const getReloadAdmin = () => {
  const url = `${RegionManager.apiEndpoint}/resources/reload/ADMIN`;
  return defaultFetch(url, 'GET');
};

export const getReloadAPI = () => {
  const url = `${RegionManager.apiEndpoint}/resources/reload/API`;
  return defaultFetch(url, 'GET');
};

export const getReloadAPP = () => {
  const url = `${RegionManager.apiEndpoint}/resources/reload/APP`;
  return defaultFetch(url, 'GET');
};

export const updateLanguagePreference = (lang: string) => {
  const url = `${RegionManager.apiEndpoint}/administrator/language/${lang}`;
  return defaultFetch(url, 'PUT');
};
