import { ColorToken } from '@styles/design-system/color/color-scheme';
import { Button } from 'antd';
import React from 'react';
import styled, { RuleSet, css } from 'styled-components';
import { Icon, IconProps } from '../icon/Icon';

export type IconButtonProps = {
  icon: IconProps['icon'];
  variant: 'basic' | 'destructive' | 'transparent' | 'applied';
  size: 'small' | 'medium';
  disabled?: boolean;
  style?: React.CSSProperties;
  className?: string;
} & React.HtmlHTMLAttributes<HTMLButtonElement>;

const iconSizeMap: Record<IconButtonProps['size'], number> = {
  small: 16,
  medium: 20,
};

const variantIconColorMap: Record<IconButtonProps['variant'], ColorToken> = {
  basic: 'Black700',
  destructive: 'AlertDestructive',
  transparent: 'Black700',
  applied: 'Purple800',
};

export const IconButton = ({
  icon,
  variant,
  size,
  disabled = false,
  style = {},
  className,
  onClick,
  ...rest
}: IconButtonProps) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    onClick?.(e);
  };

  // The color of style prop is highest priority.
  const { color: colorFromStyle, ...styleWithoutColor } = style;
  const iconSize = iconSizeMap[size];
  const iconColor: ColorToken = disabled ? 'Black300' : variantIconColorMap[variant];

  return (
    <S_Button
      style={styleWithoutColor}
      icon={
        <Icon
          style={colorFromStyle ? { color: colorFromStyle } : undefined}
          icon={icon}
          size={iconSize}
          color={iconColor}
        />
      }
      type="default"
      disabled={disabled}
      className={className}
      data-icon={icon} // Set data-icon attribute for better distinction in browser 'Elements' tab
      onClick={handleClick}
      $size={size}
      $variant={variant}
      {...rest}
    />
  );
};

const sizeButtonStyleMap: Record<IconButtonProps['size'], RuleSet<object>> = {
  small: css`
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
  `,
  medium: css`
    width: 36px;
    height: 36px;
    min-width: 36px;
    min-height: 36px;
  `,
};

const variantButtonStyleMap: Record<IconButtonProps['variant'], RuleSet<object>> = {
  basic: css`
    border: 1px solid ${({ theme }) => theme.newColor.Black200};
    background: ${({ theme }) => theme.newColor.White};

    &:hover {
      border: 1px solid ${({ theme }) => theme.newColor.Black200};
      background: ${({ theme }) => theme.newColor.Black100};
    }
    &:focus {
      border: 1px solid ${({ theme }) => theme.newColor.Black200};
    }
    &:disabled {
      border: 1px solid ${({ theme }) => theme.newColor.Black200} !important;
      background: ${({ theme }) => theme.newColor.White} !important;
    }
  `,
  destructive: css`
    border: 1px solid ${({ theme }) => theme.newColor.Red100};
    background: ${({ theme }) => theme.newColor.Red100};

    &:hover {
      border: 1px solid ${({ theme }) => theme.newColor.Red200} !important;
      background: ${({ theme }) => theme.newColor.Red200} !important;
    }
    &:focus {
      border: 1px solid ${({ theme }) => theme.newColor.Red100};
      background: ${({ theme }) => theme.newColor.Red100};
    }
    &:disabled {
      border: 1px solid ${({ theme }) => theme.newColor.Black100} !important;
      background: ${({ theme }) => theme.newColor.Black100} !important;
    }
  `,
  transparent: css`
    background: transparent;
    border: none;

    &:hover {
      background: ${({ theme }) => theme.newColor.Black100};
    }
    &:disabled {
      background: ${({ theme }) => theme.newColor.White} !important;
    }
  `,
  applied: css`
    border: 1px solid ${({ theme }) => theme.newColor.Purple400};
    background: ${({ theme }) => theme.newColor.Purple100};

    &:hover {
      border: 1px solid ${({ theme }) => theme.newColor.Purple400};
      background: ${({ theme }) => theme.newColor.Purple200} !important;
    }
    &:focus {
      border: 1px solid ${({ theme }) => theme.newColor.Purple400};
      background: ${({ theme }) => theme.newColor.Purple100};
    }
    &:disabled {
      border: 1px solid ${({ theme }) => theme.newColor.Black200} !important;
      background: ${({ theme }) => theme.newColor.White} !important;
    }
  `,
};

const S_Button = styled(Button)<{
  $size: IconButtonProps['size'];
  $variant: IconButtonProps['variant'];
}>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-shadow: none;

  ${({ $size }) => sizeButtonStyleMap[$size]}
  ${({ $variant }) => variantButtonStyleMap[$variant]}

  /* To remove animation (ant-click-animating-without-extra-node) */
  &::after {
    all: unset;
  }
`;
