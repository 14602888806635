import { Spin } from 'antd';
import styled from 'styled-components';

const LoadingWrapper = styled.div`
  text-align: center;
  padding: 60px 50px;
  margin: 20px 0;
`;

const LoadingPage = () => (
  <LoadingWrapper>
    <Spin style={{ marginRight: 10 }} /> LOADING...
  </LoadingWrapper>
);

export default LoadingPage;
