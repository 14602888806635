import { aiClipFlag } from '@components/ai-clip/ai-clip.constant';
import { useBoolean } from '@hooks/useBoolean';
import { useConfirm } from '@hooks/useModalConfirm';
import { IconButton } from '@styles/design-system/button/IconButton';
import { Text } from '@styles/design-system/text/Text';
import { isString } from 'lodash';
import React, { ReactElement } from 'react';
import styled, { css } from 'styled-components';

export enum MultiProgressPopupStatus {
  READY = 'READY',
  IN_PROGRESS = 'IN_PROGRESS',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
}

type MultiProgressPopupProps = {
  statuses: MultiProgressPopupStatus[];
  items: React.ReactNode; // Based on <MultiProgressPopupItem> component.
  texts: {
    title: string; // e.g. '다운로드 완료', '다운로드 실패', '다운로드 중'
    subtitle: string; // e.g. '2개 중 1개 다운로드 완료', '5개 중 0개 다운로드 완료, '
    subtitleFailed: string | null; // e.g. '3개 실패'
    clearModalTitle: string; // e.g. '다운로드를 중지하시겠습니까?'
    // TODO: [AI Clip] aiClipFlag.preparingDownload 제거시 clearModalContent 필드 제거
    clearModalContent?: string; // e.g. '다운로드 내용이 사라집니다'
  };
  style?: React.CSSProperties;
  clearCallback?: () => void;
};

export const MultiProgressPopup = ({
  statuses,
  items,
  texts,
  style,
  clearCallback = () => {},
}: MultiProgressPopupProps): ReactElement => {
  const { bool: isExpanded, toggleBoolean: toggleIsExpanded } = useBoolean(true);

  const { modalConfirm } = useConfirm();

  const clearPopup = () => {
    const hasInProgress = statuses.some((status) => status === MultiProgressPopupStatus.IN_PROGRESS);

    if (hasInProgress) {
      if (aiClipFlag.preparingDownload) {
        modalConfirm(texts.clearModalTitle, {
          onOk() {
            clearCallback();
          },
        });
      } else {
        modalConfirm(texts.clearModalContent ?? '', {
          title: texts.clearModalTitle,
          onOk() {
            clearCallback();
          },
        });
      }
    } else {
      clearCallback();
    }
  };

  return (
    <S_Host style={style} $isExpanded={isExpanded}>
      <S_Header>
        <S_TextGroup>
          <Text style={{ fontWeight: 600 }} variant="BodyM" color="Black700">
            {texts.title}
          </Text>

          <Text variant="CaptionM" color="Black500">
            {texts.subtitle}

            {!aiClipFlag.preparingDownload && isString(texts.subtitleFailed) && (
              <Text span variant="CaptionM" color="AlertDestructive">
                {texts.subtitleFailed}
              </Text>
            )}
          </Text>
        </S_TextGroup>

        <S_ButtonGroup>
          <IconButton
            icon={isExpanded ? 'arrowDownSLine' : 'arrowUpSLine'}
            variant="basic"
            size="medium"
            onClick={toggleIsExpanded}
          />
          <IconButton icon="closeLine" variant="basic" size="medium" onClick={clearPopup} />
        </S_ButtonGroup>
      </S_Header>

      <S_ItemList $isExpanded={isExpanded}>{items}</S_ItemList>
    </S_Host>
  );
};

const HEADER_HEIGHT = 68;

const S_Host = styled.div<{ $isExpanded: boolean }>`
  display: flex;
  flex-direction: column;
  width: 360px;
  height: auto;
  border-radius: 8px;
  background: ${({ theme }) => theme.newColor.White};
  box-shadow: ${({ theme }) => theme.shadow.depth4};
  transition: max-height 0.3s ease;

  ${({ $isExpanded }) =>
    $isExpanded
      ? css`
          max-height: 290px;
          overflow: auto;
        `
      : css`
          max-height: ${HEADER_HEIGHT}px;
          overflow: hidden;
        `}
`;

const S_Header = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px;
  min-height: ${HEADER_HEIGHT}px;
  border-bottom: 1px solid ${({ theme }) => theme.newColor.Black200};
`;

const S_TextGroup = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const S_ButtonGroup = styled.div`
  display: flex;
  gap: 8px;
`;

const S_ItemList = styled.div<{ $isExpanded: boolean }>`
  max-height: ${({ $isExpanded }) => ($isExpanded ? '240px' : '0')};
  overflow: auto;
  transition: max-height 0.3s ease;
  overscroll-behavior: contain;
`;
