import { ColorToken } from '@styles/design-system/color/color-scheme';
import React, { forwardRef } from 'react';
import { styled, useTheme } from 'styled-components';
import * as IconFile from './icon.helper';

type IconName = keyof typeof IconFile;

export type IconProps = {
  icon: IconName;
  size: number;
  /**
   * @default '#000000'
   */
  color?: ColorToken;
  style?: React.CSSProperties;
  className?: string;
} & React.HtmlHTMLAttributes<HTMLSpanElement>;

export const Icon = forwardRef<HTMLSpanElement, IconProps>(
  ({ icon, size, color, style = {}, className, onClick, ...rest }: IconProps, ref) => {
    const theme = useTheme();

    const IconComponent = IconFile[icon]; // eslint-disable-line

    // The color of style prop is highest priority.
    const { color: colorFromStyle, ...styleWithoutColor } = style;
    const iconColor = color ? theme.newColor[color] : '#000000';
    const finalIconColor = colorFromStyle ?? iconColor;

    return (
      <S_Host
        ref={ref}
        style={{
          width: size,
          height: size,
          cursor: onClick ? 'pointer' : undefined,
          ...styleWithoutColor,
        }}
        className={className}
        data-icon={icon} // Set data-icon attribute for better distinction in browser 'Elements' tab
        onClick={onClick}
        {...rest}
      >
        <IconComponent width={size} height={size} color={finalIconColor} />
      </S_Host>
    );
  },
);

const S_Host = styled.span`
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  display: inline-block;
  font-size: 0;
`;
