import { useEffect, useMemo, useState } from 'react';

import { LANG_CODE_FOR_KEY_CHECK, languageManager, t } from '@common/helper/languages';
import { ReactComponent as LanguageIcon } from '@assets/svg/sidemenu-language.svg';

import { Button, Dropdown, MenuProps, Modal, Progress, Radio } from 'antd';
import { useAuthorityDispatch, useAuthorityState } from '@context/authority/AuthorityContext';
import {
  getReloadAPI,
  getReloadAPP,
  getReloadAdmin,
  insertResourcesImport,
  updateLanguagePreference,
} from '@api/shoplive/admin';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { StyledMultiLanguageStatusWrapper } from './styled';
import { ReactComponent as CheckIcon } from '@assets/svg/Fill__Systems--checkbox-circle-fill.svg';

type ChildProps = {
  className?: string;
};
const LanguageDropdownMenu = ({ className }: ChildProps) => {
  const { isGlobalSuperAdmin, language, myFunctions } = useAuthorityState();
  const { setLanguage } = useAuthorityDispatch();

  const [selectedLanguage, setSelectedLanguage] = useState<string>(language);

  useEffect(() => {
    // context 변경값에 반응하지않아 setState와 비교하여 UI 상태값 업데이트
    if (selectedLanguage !== language) {
      setSelectedLanguage(language);
    }
  }, [language]);

  const onChangeLanguage = async (menuInfo: any) => {
    const { key: languageClicked } = menuInfo;
    const langCode = languageClicked;

    if (langCode !== language) {
      try {
        const lang = await languageManager.setCurrentLanguageAndRefresh(langCode);
        setLanguage(lang);
        setSelectedLanguage(langCode);
        await updateLanguagePreference(lang === 'keyCheck' ? 'ko' : lang);
      } catch (error: any) {
        console.error(error);
      }
    }
    return;
  };

  const onClickMultiLanguageImport = async () => {
    let currentProgress = 0;

    const apiCalls = [
      {
        apiCall: insertResourcesImport,
        title: 'resource/import',
        status: 'confirm', // 'confirm' 또는 'success'
      },
      {
        apiCall: getReloadAdmin,
        title: 'reload/ADMIN',
        status: 'confirm',
      },
      {
        apiCall: getReloadAPI,
        title: 'reload/API',
        status: 'confirm',
      },
      {
        apiCall: getReloadAPP,
        title: 'reload/APP',
        status: 'confirm',
      },
    ];
    const totalSteps = apiCalls.length;
    const progressIncrement = 100 / totalSteps;

    const updateContent = () => (
      <StyledMultiLanguageStatusWrapper className="multi-language-modal-wrapper">
        <Progress percent={currentProgress} />
        {apiCalls.map((item) => (
          <span key={item.title} className={`multi-language-item ${item.status}`}>
            {item.status === 'success' && <CheckIcon />}
            {item.title}
          </span>
        ))}
      </StyledMultiLanguageStatusWrapper>
    );

    const confirmModal = Modal.confirm({
      title: t('page.multi_language.import.really.message_success'),
      icon: <ExclamationCircleOutlined />,
      okText: t('button.confirm'),
      cancelText: t('button.cancel'),
      closable: false,
      maskClosable: false,
      keyboard: false,
      async onOk() {
        return new Promise(async (resolve, reject) => {
          confirmModal.update({
            okButtonProps: { loading: true },
            cancelButtonProps: { style: { display: 'none' } },
            content: updateContent(),
          });

          try {
            for (const item of apiCalls) {
              await item.apiCall();
              item.status = 'success';
              currentProgress += progressIncrement;
              confirmModal.update({ content: updateContent() });
            }
            // API 호출 완료 후, 확인 버튼 텍스트 변경 및 모달 유지
            confirmModal.update({
              okButtonProps: { loading: false, style: { display: 'none' } },
              cancelText: t('button.close'),
              cancelButtonProps: { style: { display: 'inline-block' }, type: 'primary' },
            });
          } catch (error) {
            console.error(error);
            Modal.error({
              title: t('page.multi_language.import.message_fail'),
              content: t('page.multi_language.import.message_fail'),
            });
            reject();
          }
        });
      },
    });
  };

  const items: MenuProps['items'] = useMemo(() => {
    let list = [
      {
        key: 'en',
        label: (
          <div className="custom-dropdown-menu no-active" style={{ justifyContent: 'space-between' }}>
            <div className="language-title">English</div>
            <Radio className="custom-common-radio-small" checked={selectedLanguage === 'en'} />
          </div>
        ),
        onClick: onChangeLanguage,
      },
      {
        key: 'ko',
        label: (
          <div className="custom-dropdown-menu" style={{ justifyContent: 'space-between' }}>
            <div className="language-title">한국어</div>
            <Radio className="custom-common-radio-small" checked={selectedLanguage === 'ko'} />
          </div>
        ),
        onClick: onChangeLanguage,
      },
    ];

    if (isGlobalSuperAdmin || myFunctions.JAPANESE) {
      list.push({
        key: 'ja',
        label: (
          <div className="custom-dropdown-menu" style={{ justifyContent: 'space-between' }}>
            <div className="language-title">日本語</div>
            <Radio className="custom-common-radio-small" checked={selectedLanguage === 'ja'} />
          </div>
        ),
        onClick: onChangeLanguage,
      });
    }

    // navigator.language에서 나라 코드만 추출한다. (예: en-US -> en)
    const currentLang = navigator.language.substring(0, 2);
    const currentLangObject = list.find((lang) => lang.key === currentLang);
    if (currentLangObject) {
      // 현재 설정된 언어를 배열에서 제거한다.
      list = list.filter((lang) => lang.key !== currentLang);
      // 제거한 언어를 배열의 첫 번째 위치에 추가한다.
      list.unshift(currentLangObject);
    }

    if (isGlobalSuperAdmin) {
      list.push({
        key: LANG_CODE_FOR_KEY_CHECK,
        label: (
          <div className="custom-dropdown-menu italic" style={{ justifyContent: 'space-between' }}>
            <div className="language-title">키 확인</div>
            <Radio className="custom-common-radio-small" checked={selectedLanguage === LANG_CODE_FOR_KEY_CHECK} />
          </div>
        ),
        onClick: onChangeLanguage,
      });
    }
    if (isGlobalSuperAdmin) {
      list.push({
        key: 'import-btn',
        label: <Button type="primary">{t('page.multi_language.import.title')}</Button>,
        onClick: onClickMultiLanguageImport,
      });
    }

    return list;
  }, [selectedLanguage]);

  return (
    <Dropdown
      menu={{ items }}
      placement="topLeft"
      trigger={['click']}
      overlayClassName={`campaign-list-dropdown no-active-dropdown ${className ? className : ''}`}
    >
      <div className="custom-dropdown-menu">
        <LanguageIcon width="16" height="16" className="shoplive-icon" />
        {t('menu.languages')}
      </div>
    </Dropdown>
  );
};

export default LanguageDropdownMenu;
