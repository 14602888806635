import { t } from '@common/helper/languages';
import { Param } from '@common/param';

export const VIEW_TYPE = {
  CARD: 'CARD',
  LIST: 'LIST',
};

export const VIDEOS_DEFAULT_QUERY = {
  [Param.PAGE]: 1,
  [Param.LIMIT]: 30,
};

export const SOURCE = {
  VIDEO: 'video',
  CAMPAIGN: 'campaign',
};

export const invalid_img_url = 'https://image.shoplive.cloud/bea299be-3a35-4e1b-936f-cb4ea0db6de2.jpg';

type VideoMetadata = {
  duration: number;
  width: number;
  height: number;
};

export const getVideoMetadata = async (file: File): Promise<VideoMetadata> => {
  const fileCallbackToPromise = (fileObj: HTMLVideoElement): Promise<VideoMetadata> =>
    Promise.race([
      new Promise<VideoMetadata>((resolve, reject) => {
        const video = fileObj;
        video.onloadedmetadata = () => {
          resolve({
            duration: video.duration, // Seconds
            width: video.videoWidth,
            height: video.videoHeight,
          });
        };
        video.onerror = () => {
          let errorMessage = 'Failed to load video metadata for "' + file.name + '".';
          if (video.error) {
            switch (video.error.code) {
              case video.error.MEDIA_ERR_ABORTED:
                // 사용자가 비디오 로딩을 중단했을 때 발생(예를 들어, 사용자가 페이지를 떠나거나 비디오 로딩을 취소하는 등의 행동을 취했을 때)
                errorMessage = `${t('error.media_error_aborted')} (${file.name})`;
                break;
              case video.error.MEDIA_ERR_NETWORK:
                // 비디오를 다운로드하는 과정에서 네트워크 에러가 발생했을 때 (일시적인 인터넷 연결 문제일 수도 있고, 서버 측의 문제일 수도 있음)
                errorMessage = `${t('error.media_error_network')} (${file.name})`;
                break;
              case video.error.MEDIA_ERR_DECODE:
                // 비디오 파일의 디코딩 과정에서 문제가 발생했을 때 (이는 비디오 파일이 손상되었거나, 현재 브라우저에서 지원하지 않는 코덱을 사용하는 비디오 파일을 재생하려고 할 때 발생)
                errorMessage = `${t('error.media_error_decode')} (${file.name})`;
                break;
              case video.error.MEDIA_ERR_SRC_NOT_SUPPORTED:
                // 주로 비디오 소스의 형식이 브라우저에서 지원되지 않을 때 발생
                errorMessage = `${t('error.media_error_src_not_supported')} (${file.name})`;
                break;
              default:
                errorMessage = `An unknown error occurred. (${file.name})`;
                break;
            }
          }
          console.error(`Video error (${video.error?.code}): ${errorMessage}`);
          reject(new Error(errorMessage));
        };
      }),
      new Promise<VideoMetadata>((_, reject) => {
        setTimeout(() => reject(new Error('Timeout: Failed to load video metadata within 5 seconds.')), 5000);
      }),
    ]);

  const objectUrl = URL.createObjectURL(file);
  const video = document.createElement('video');
  video.preload = 'metadata';
  video.src = objectUrl;

  return await fileCallbackToPromise(video);
};
