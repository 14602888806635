import { TListResponse } from '@localTypes/response';
import RegionManager from '@common/regions';

import defaultFetch, { fetchFormData } from '@common/libs/fetch';

import useSWR from 'swr';
import { TBriefCustomer } from '@localTypes/administrator';

/**
 * @description accessToken 기반 사용자 조회
 */
export const getMe = () => {
  let url = `${RegionManager.apiEndpoint}/administrator/me`;
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (timezone) {
    url += `?timezone=${encodeURIComponent(timezone)}`;
  }
  return defaultFetch(url, 'GET');
};

/**
 * @description 메인 콘솔에서 방송 로그인을 위한 authorization 발급
 */
export const createAuthorization = async (customerId: string, validDuration: number = 86400) => {
  const formData = new FormData();
  formData.append('validDuration', validDuration + '');
  formData.append('customerId', customerId);

  return await fetchFormData(`${RegionManager.apiEndpoint}/administrator/authorization`, 'POST', formData);
};

/**
 * @description 자식 고객사 목록 (자신 포함)
 */
export const getCustomerChildren = () => {
  const url = `${RegionManager.apiEndpoint}/customers/children`;
  return useSWR<TListResponse<TBriefCustomer>>(url);
};

/**
 * 현재 선택되어있는 고객사를 전환한다.
 */
export const recordLatestCustomer = (customerId: string) => {
  const url = `${RegionManager.apiEndpoint}/administrator/latest/${customerId}`;
  return defaultFetch(url, 'PUT');
};

export const possessAdministrator = (adminId: string) => {
  const url = `${RegionManager.apiEndpoint}/administrator/authorization/possess?adminId=${adminId}`;
  return defaultFetch(url, 'POST');
};
