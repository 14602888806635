import { css } from 'styled-components';

export const textScheme = {
  H1: css`
    font-size: 42px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px;
  `,
  H2: css`
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
  `,
  H3: css`
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
  `,
  H4: css`
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  `,
  H5: css`
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  `,
  ButtonM: css`
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  `,
  ButtonS: css`
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  `,
  ButtonXS: css`
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
  `,

  BodyM: css`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  `,
  BodyS: css`
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  `,
  CaptionL: css`
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  `,
  CaptionM: css`
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
  `,
  CaptionS: css`
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
  `,
};

export type TextToken = keyof typeof textScheme;
