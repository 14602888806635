import { Tooltip, TooltipProps } from 'antd';

const toolTopOverlayInnerStyle = {
  borderRadius: '6px',
  backgroundColor: 'hsla(0,0%,20%,1)',
};

function CustomToolTip({ children, overlayInnerStyle, ...rest }: TooltipProps) {
  return (
    <Tooltip
      title="Copied!"
      trigger="click"
      color="hsla(0,0%,20%,1)"
      overlayInnerStyle={{
        ...toolTopOverlayInnerStyle,
        ...overlayInnerStyle,
      }}
      {...rest}
    >
      {children}
    </Tooltip>
  );
}

export default CustomToolTip;
