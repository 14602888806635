export const HLS_OPTIONS = {
  defaultAudioCodec: 'mp4a.40.2',
  startFragPrefetch: true,
  stretchShortVideoTrack: true,
  maxBufferLength: 10, // 최대 버퍼 길이를 10초로 설정
  maxMaxBufferLength: 30, // 최대 버퍼 길이를 30초로 설정
  maxBufferSize: 60 * 1000 * 1000, // 최대 버퍼 크기를 60MB로 설정
  maxBufferHole: 0.5, // 최대 버퍼 홀을 0.5초로 설정
};

export const AI_CLIP_HLS_OPTIONS = {
  ...HLS_OPTIONS,
  maxBufferLength: 5, // seconds
  backBufferLength: Infinity,
  maxMaxBufferLength: 5, // seconds
  abrBandWidthUpFactor: 0.85,
};
