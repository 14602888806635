import React, { CSSProperties } from 'react';
import { styled } from 'styled-components';
import { SidebarBadge, SidebarBadgeType } from './SidebarBadge';

type Props = {
  label: string;
  badge?: SidebarBadgeType;
  style?: CSSProperties;
};

export const SideMenuLabel: React.FC<Props> = ({ label, badge, style }) => {
  return (
    <StyledLabel style={style}>
      {label}
      {badge && <SidebarBadge style={{ marginLeft: 8 }} badge={badge} />}
    </StyledLabel>
  );
};

const StyledLabel = styled.div`
  margin: 20px 0 4px 0;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.newColor.Black700};
  text-transform: uppercase;
  padding: 0 16px;

  display: flex;
  align-items: center;
`;
