import RegionManager from '@common/regions';
import * as qs from 'querystring';
import defaultFetch from '@common/libs/fetch';
import { TVideoUploadable } from '@localTypes/video';

// 비디오 목록 가져오기
export const getVideos = (query: any, isVideoDemo = false, isMonitor = false) => {
  return `${RegionManager.apiEndpoint}/video${isVideoDemo ? '/demo' : isMonitor ? '/monitor' : ''}?${qs.encode(query)}`;
};

// 개별 비디오 가져오기
export const getVideo = (videoId: string) => {
  const url = `${RegionManager.apiEndpoint}/video/${videoId}`;
  return defaultFetch(url, 'GET');
};

//캠페인 리플레이 가져오기
export const getCampaignVideos = (query: any) => {
  return `${RegionManager.apiEndpoint}/shorts/source/campaign?sortAscending=false&${qs.encode(query)}`;
};

// 캠페인 개별 리플레이 가져오기
export const getCampaignVideo = (campaignId: string) => {
  const url = `${RegionManager.apiEndpoint}/shorts/source/campaign/${campaignId}`;
  return defaultFetch(url, 'GET');
};

// 비디오 업로드 상태 조회
export const getVideoUploadProgress = (videoId: string) => {
  const url = `${RegionManager.apiEndpoint}/video/${videoId}/progress`;
  return defaultFetch(url, 'GET');
};

// 비디오 제거
export const removeVideoItem = (videoId: string) => {
  const url = `${RegionManager.apiEndpoint}/video?videoIds=${videoId}`;
  return defaultFetch(url, 'DELETE');
};

export const getVideoUploadable = (): Promise<TVideoUploadable> => {
  const url = `${RegionManager.apiEndpoint}/video/uploadable`;
  return defaultFetch(url, 'GET');
};

export const getVideoAiClipUploadable = (): Promise<TVideoUploadable> => {
  const url = `${RegionManager.apiEndpoint}/video/ai/clip/uploadable`;
  return defaultFetch(url, 'GET');
};
