import { TShortsValidateType } from '@localTypes/shortform';
import { StateCreator } from 'zustand';

type ShortformState = {
  isWriterNeedValidate: boolean;
  setIsWriterNeedValidate: (isWriterNeedValidate: boolean) => void;
  validateType: TShortsValidateType;
  setValidateType: (validateType: TShortsValidateType) => void;
  shortformListCount: number;
  setShortformListCount: (shortformListCount: number) => void;
};

export const createShortFormDetailSlice: StateCreator<ShortformState> = (set) => ({
  isWriterNeedValidate: false,
  setIsWriterNeedValidate: (isWriterNeedValidate: boolean) =>
    set(() => ({
      isWriterNeedValidate,
    })),
  validateType: 'DEFAULT',
  setValidateType: (validateType: TShortsValidateType) =>
    set(() => ({
      validateType,
    })),
  shortformListCount: 0,
  setShortformListCount: (shortformListCount: number) =>
    set(() => ({
      shortformListCount,
    })),
});
