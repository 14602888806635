const BEARER_PREFIX = 'Bearer ';
const LOCAL_STORAGE_KEY_ACCESSTOKEN = 'accessToken';
const LOCAL_STORAGE_KEY_TOKEN_CREATED_AT = 'atknCrtAt';
export const SESSION_STORAGE_CUSTOMER_ID = 'ssci';
const TOKEN_VALID_DURATION = 86400000; //하루.

export const SESSION_KEY_ADMIN_ORIGIN_JWT = 'admorgjwt';
export const SESSION_KEY_ADMIN_ORIGIN_NAME = 'admorgnm';

let consoleSessionUID: string | null = null;

import { languageManager } from './languages';
import RegionManager, { IAdminApiEndpoint } from '@common/regions';

export const getToken = () => {
  const accessToken = localStorage?.getItem(LOCAL_STORAGE_KEY_ACCESSTOKEN);
  const tokenCreatedAt = localStorage?.getItem(LOCAL_STORAGE_KEY_TOKEN_CREATED_AT);
  const customerId = sessionStorage?.getItem(SESSION_STORAGE_CUSTOMER_ID);

  //토큰 없으면 리턴 null
  if (!accessToken) {
    return null;
  }

  //유효기간 지났으면 리턴 null
  if (Number(tokenCreatedAt ?? 0) < new Date().getTime() - TOKEN_VALID_DURATION) {
    return null;
  }

  const header: any = {
    Authorization: `${BEARER_PREFIX}${accessToken}`,
  };

  if (consoleSessionUID) {
    header.uid = consoleSessionUID;
  }
  if (customerId) {
    header['x-sl-customer-id'] = customerId;
  }

  if (languageManager.currentLanguage) {
    header['x-sl-language'] = languageManager.currentLanguage;
  }
  return header;
};

//accessToken 저장하기. 유효 날짜와 함께 저장한다.
export const saveToken = (
  token: string,
  availableRegions: IAdminApiEndpoint[] | null | undefined,
  apiEndpoint: string | null | undefined,
) => {
  localStorage?.setItem(LOCAL_STORAGE_KEY_ACCESSTOKEN, token.replace(BEARER_PREFIX, ''));
  localStorage?.setItem(LOCAL_STORAGE_KEY_TOKEN_CREATED_AT, new Date().getTime() + '');

  if (apiEndpoint) {
    // console.log('Save api endpoint : ', apiEndpoint);
    RegionManager.saveApiEndpoint(apiEndpoint);
  }

  if (availableRegions) {
    // console.log('Save availableRegions : ', availableRegions);
    RegionManager.saveAvailableRegions(availableRegions);
  }
};

export const setSessionStorageCustomerId = (customerId: string) => {
  sessionStorage?.setItem(SESSION_STORAGE_CUSTOMER_ID, customerId);
};

export const getCustomerId = () => {
  return sessionStorage?.getItem(SESSION_STORAGE_CUSTOMER_ID);
};

// 로그아웃할때, 세션의 모든 정보 지워준다.
export const removeToken = () => {
  sessionStorage?.clear();
  localStorage?.removeItem(LOCAL_STORAGE_KEY_ACCESSTOKEN);
  localStorage?.removeItem(LOCAL_STORAGE_KEY_TOKEN_CREATED_AT);
};

export const updateConsoleSessionUID = (uid: string | null) => {
  consoleSessionUID = uid;
};

export const getLoginPath = (tokenExpired = false) => {
  if (tokenExpired) {
    return '/login';
  }

  const currentPath = location.hash.replace('#', '');
  if (currentPath.startsWith('/login')) {
    return currentPath;
  }

  return `/login?returnUrl=${encodeURIComponent(currentPath)}`;
};

export const getAccessToken = () => {
  try {
    const accessToken = localStorage?.getItem(LOCAL_STORAGE_KEY_ACCESSTOKEN);
    if (!accessToken) {
      return null;
    }
    return accessToken;
  } catch {
    throw new Error('failed to get access token');
  }
};
