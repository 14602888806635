import { useAuthorityState } from '@context/authority/AuthorityContext';
import { useCallback, useMemo } from 'react';
import { useNavigate, useLocation, useSearchParams, useParams } from 'react-router-dom';

export const useRouter = () => {
  const { administrator } = useAuthorityState();

  const navigate = useNavigate();
  const params = useParams();
  const { pathname, state } = useLocation();
  const [searchParams] = useSearchParams();

  const query = useMemo(() => {
    const q: any = {};
    for (const key of searchParams.keys()) {
      q[key] = searchParams.get(key);
    }
    return q;
  }, [searchParams]);

  // buildSearchString 할때, query 에 _ci 일괄 처리해주기.
  const buildSearchString = useCallback(
    (query?: any) => {
      const searchParams = new URLSearchParams(query);
      if (!searchParams.get('_ci') && administrator?.currentCustomerId) {
        searchParams.set('_ci', administrator.currentCustomerId);
      }
      return searchParams.toString();
    },
    [administrator],
  );

  const push = useCallback(
    (pathOrObject: string | { pathname: string; query?: any }) => {
      if (typeof pathOrObject === 'string') {
        navigate(pathOrObject);
      } else {
        const { pathname, query } = pathOrObject;
        const newSearch = buildSearchString(query);
        navigate(`${pathname}?${newSearch}`);
      }
    },
    [navigate],
  );

  const replace = useCallback(
    (pathOrObject: string | { pathname: string; query?: any }) => {
      if (typeof pathOrObject === 'string') {
        navigate(pathOrObject, { replace: true });
      } else {
        const { pathname, query } = pathOrObject;
        const newSearch = buildSearchString(query);
        navigate(`${pathname}?${newSearch}`, { replace: true });
      }
    },
    [navigate],
  );

  const back = useCallback(() => navigate(-1), [navigate]);

  return {
    push,
    replace,
    back,
    query,
    pathname,
    state,
    params,
  };
};
