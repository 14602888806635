export enum ShortformNoticeStatus {
  OPEN = 'OPEN', // 공개
  HIDDEN = 'HIDDEN', // 비공개
  SCHEDULED = 'SCHEDULED', // 예약
}

export type TShortformNotice = {
  id: string;
  customerId: string;
  status: ShortformNoticeStatus;
  startAt: number | null;
  endAt: number | null;
  title: string;
  content: string | null; // if `content` is null, `imageUrl` must be string.
  imageUrl: string | null; // if `imageUrl` is null, `content` must be string.
  linkUrl: string | null; // 이미지 클릭시 연결되는 링크 URL
  displayPriority: number; // 1 is top priority. (Available 1 ~ 99)
  createAdminId: string;
  createAdminName: string;
  updateAdminId: string | null;
  updateAdminName: string | null;
  createdAt: number;
  updatedAt: number | null;
};

export type TCreateShortformNotice = {
  status: ShortformNoticeStatus;
  startAt: number | null;
  endAt: number | null;
  title: string;
  content: string | null;
  imageUrl: string | null;
  linkUrl: string | null;
  displayPriority: number;
};

export type TUpdateShortformNotice = {
  id: string;
} & TCreateShortformNotice;

export type TShortformNoticeAdmin = {
  createAdminId: string;
  createAdminName: string;
};
